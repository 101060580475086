.drag-drop-overlay {
    text-align: center;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(32, 78, 103, 0.10);
        backdrop-filter: blur(10px);
        color: #204E67;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: large;
        z-index: 10;
        transition: backdrop-filter 0.1s, background-color 1s;
    }

    .overlay-icon {
        width: 211px;
        margin-bottom: 36px;
    }

    @starting-style {
        .overlay {
            backdrop-filter: blur(0px);
            background-color: transparent;
        }
    }

    .content {
        z-index: 1;
        text-align: initial;
    }
}