// -----------------------------------------------------------------------------
// Toasts
// -----------------------------------------------------------------------------

.toast-messages {
  position: fixed;
  top: calc(var(--g-header-height) - $g-spacer-700);
  left: 50%;
  transform: translateX(-50%);
  z-index: $g-toast-zindex;
  width: 95vw;

  @include media-breakpoint-up(md) {
    width: $g-toast-width;
  }

  .alert {
    box-shadow: $g-box-shadow-100;
  }
}
