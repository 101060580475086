// -----------------------------------------------------------------------------
// Shadow
// -----------------------------------------------------------------------------

.shadow-base {
  box-shadow: $g-box-shadow;
}

.shadow-100 {
  box-shadow: $g-box-shadow-100;
}

.shadow-200 {
  box-shadow: $g-box-shadow-200;
}

.shadow-300 {
  box-shadow: $g-box-shadow-300;
}
