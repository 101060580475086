h1,
.h1 {
  @include g-apply-text-style('h1');
}

h2,
.h2 {
  @include g-apply-text-style('h2');
}

h3,
.h3 {
  @include g-apply-text-style('h3');
}

h4,
.h4 {
  @include g-apply-text-style('h4');
}

h5,
.h5 {
  @include g-apply-text-style('h5');
}

p,
li {
  @include g-apply-text-style('regular-200');
}
