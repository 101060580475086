// -----------------------------------------------------------------------------
// Details panel
// -----------------------------------------------------------------------------

.details-panel {
  min-height: 70px;
  border: $g-border;
  border-radius: $border-radius;
  .items {
    flex-basis: g-px-to-rem(1100px);
    label {
      letter-spacing: 3px;
      line-height: 18px;
      font-size: 12px;
    }
  }
}
