.steps {
  display: inline-flex;
  align-items: center;
  margin-left: $g-spacer-base;

  &__item {
    @include g-apply-text-style('medium-400');

    display: inline-block;
    color: $g-gray-500;
    padding: 0 $g-spacer-300;
    line-height: 2;

    &:not(:last-child) {
      border-right: $g-border-width solid $g-border-color;
    }

    &.is-active {
      @include g-apply-text-style('h3');

      color: $g-gray-1000;
    }
  }
}
