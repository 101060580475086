.messageContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 12px;
}

.userMessage {
  padding-left: 75px;
  text-align: right;
}

.botMessage {
  padding-right: 75px;
  text-align: left;
}

.messageContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.messageText {
  align-self: stretch;
  flex: 1;
  font-size: 14px;
  width: 100%;
  gap: 4px;
  padding: 8px;
}

.userMessageText {
  border-radius: 16px;
  background-color: rgba(237, 239, 242, 1);
}
