.container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 16px;
  border-right: 1px solid #D9D9D9;
  text-align: center;
  justify-content: start;
  font: 500 14px/1 Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
}
