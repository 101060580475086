.title {
  color: #191919;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.titleContainer {
  padding: 12px 24px;
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.10);
}
.bodyContainer {
  gap: 8px;
  padding: 16px 24px;
}
.footerContainer {
  padding: 12px 24px;
  box-shadow: 4px 0 12px 0 rgba(0, 0, 0, 0.10);
  gap: 1rem;
}
.qrContainer {
  position: relative;
}
.button {
  color: var(--Color-Grey-900, #212529);
  font-size: 14px;
  font-weight: 600;
}
.qrLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}
.qr {
  padding: 20px;
}
.subtitle {
  color: var(--Schemes-On-Surface-Variant, #49454F);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
