.attachment-preview {
  align-items: center;
  padding: 0 8px;
  display: flex;
  gap: 8px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid var(--Color-Grey-100, #F5F4F7);

  &__thumbnail {
    width: 48px;
    height: 48px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0.25rem;
    }
  }

  &__info {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: var(--Color-Grey-900, #010028);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &__description {
    font-size: 0.6875rem;
    line-height: 1.2;
    color: var(--Color-Grey-700, #797980);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: auto;
  }

  &__domain {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.625rem;
    color: var(--Color-Grey-600);
    margin-top: 0.25rem;

    img {
      width: 1rem;
      height: 1rem;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
