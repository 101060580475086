/* stylelint-disable selector-class-pattern, keyframes-name-pattern */
// -----------------------------------------------------------------------------
// Fade in / out with Visible
// -----------------------------------------------------------------------------

.fadeIn {
  animation: $g-fade-anim-duration fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1% {
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.fadeOut {
  animation: $g-fade-anim-duration fadeOut;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  99% {
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
