/* stylelint-disable scss/no-global-function-names, scss/at-rule-conditional-no-parentheses, value-keyword-case */

@mixin g-apply-text-style($textItem, $textStyles: $g-text-styles) {
  @each $textType, $textStyle in $textStyles {
    @if ($textType == $textItem) {
      $textProperties: map-get($textStyle, defaultStyles);
      $textBreakpoints: map-get($textStyle, rwd);

      @each $textDefaultStyles, $textProperty in $textProperties {
        @if (map-has-key($textStyle, defaultStyles)) {
          #{$textDefaultStyles}: map-get($textProperties, $textDefaultStyles);
        }
      }

      @each $textResponsive, $textBreakpoint in $textBreakpoints {
        @if (map-has-key($textStyle, rwd)) {
          @include media-breakpoint-up(#{$textResponsive}) {
            $textBreakpointstyles: map-get($textBreakpoints, $textResponsive);

            @each $textBreakpointProperty,
              $textBreakpointstyle in $textBreakpointstyles
            {
              #{$textBreakpointProperty}: map-get(
                $textBreakpointstyles,
                $textBreakpointProperty
              );
            }
          }
        }
      }
    }
  }
}
