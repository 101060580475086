.container {
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  max-width: 300px;
  flex-direction: column;
  text-align: center;
  justify-content: start;
  padding: 1rem;
  margin-top: 2rem;
}

.title {
  color: var(--Color-Grey-900, #212529);
  font: 600 14px/20px Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
}

.description {
  color: var(--Color-Grey-700, #6a6d70);
  margin-top: 0.5rem;
  font: 400 12px/16px Uncut Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
