// -----------------------------------------------------------------------------
// Additional text utility classes
// -----------------------------------------------------------------------------

.medium-100 {
  @include g-apply-text-style('medium-100');
}

.medium-200 {
  @include g-apply-text-style('medium-200');
}

.medium-200-semi-bold {
  @include g-apply-text-style('medium-200-semi-bold');
}

.medium-300 {
  @include g-apply-text-style('medium-300');
}

.medium-300-semi-bold {
  @include g-apply-text-style('medium-300-semi-bold');
}

.medium-400 {
  @include g-apply-text-style('medium-400');
}

.medium-500 {
  @include g-apply-text-style('medium-500');
}

.regular-100 {
  @include g-apply-text-style('regular-100');
}

.regular-200 {
  @include g-apply-text-style('regular-200');
}

.regular-300 {
  @include g-apply-text-style('regular-300');
}

.regular-400 {
  @include g-apply-text-style('regular-400');
}

.regular-300-gray-700 {
  @include g-apply-text-style('regular-300-gray-700');
}

.regular-500 {
  @include g-apply-text-style('regular-500');
}

.regular-600 {
  @include g-apply-text-style('regular-600');
}

.fw-regular {
  font-weight: $g-fw-regular;
}

.fw-medium {
  font-weight: $g-fw-medium;
}

.fw-semi-bold {
  font-weight: $g-fw-semi-bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-first-letter-capitalize {
  display: inline-block;
  text-transform: lowercase;
  animation: render 0.01s; // Firefox width bug workaround https://bugzilla.mozilla.org/show_bug.cgi?id=385615

  &::first-letter {
    text-transform: capitalize !important;
  }
}

@keyframes render {
  from {
    padding-right: 0.1px;
  }

  to {
    padding-right: 0;
  }
}

.text-space-after {
  &::after {
    content: ' ';
    display: inline-block;
    white-space: pre;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.lh-default {
  line-height: 16px;
}

.lh-odd {
  line-height: 15px;
}

.text-horizontal-line {
  --line-color: #{$g-gray-200};

  &[data-horizontal-line='primary-100'] {
    --line-color: #{$g-color-primary-300};
  }

  display: flex;
  align-items: center;
  gap: calc(var(--g-grid-gutter) / 2);

  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: var(--line-color);
  }
}
