.section {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.sectionTitle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  flex: 1;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  color: var(--Color-Grey-600, #999B9D);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.24px;
  line-height: 1;
}

.sectionContent {
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  color: var(--Color-Grey-900, #010028);
  font-weight: 400;
  line-height: 24px;
}

.contentWrapper {
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
}

.minHeightControl {
  display: flex;
  min-height: 60px;
}

.contentContainer {
  border-radius: 4px;
  min-width: 240px;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: start;
  flex: 1;
  flex-basis: 0;
  padding: 10px 0;
}

.textContent {
  flex: 1;
  align-self: stretch;
  min-width: 240px;
  width: 100%;
  margin: auto 0;
}

.textContentEdit {
  border-radius: 8px;
  border: 1px solid #3C3C46;
  background: var(--Color-Grey-000, #FFF);
  padding: 10px;
}
