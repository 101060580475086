/* stylelint-disable no-descending-specificity */

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-tabs {
  border-bottom: 1px solid $g-gray-200;
  margin-top: $g-spacer-750;

  .nav-link {
    $nav-link-height: 50px;
    $nav-link-shadow-height: 2px;

    font-size: $g-fs-base;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: var(--g-grid-gutter);
    color: $g-gray-500;
    min-height: $nav-link-height;
    min-width: 150px;

    &:hover:not(:disabled) {
      cursor: pointer;
      color: $g-gray-900;
    }

    &.active {
      min-height: $nav-link-height - $nav-link-shadow-height;
      color: $g-gray-900;
      background-color: $g-color-white;
      box-shadow:
        0 $nav-link-shadow-height 0 $g-color-primary,
        $g-box-shadow-200;
      font-weight: $g-fw-semi-bold;
    }
  }

  .nav-item {
    @include media-breakpoint-down(md) {
      flex-grow: 1;
    }

    a.nav-link {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.nav-tabs-compact {
  @include media-breakpoint-down(xl) {
    white-space: nowrap;
    border-bottom: $g-border;
  }

  .nav-link {
    display: block;
    padding: $g-spacer-200 0;
    margin-right: $g-spacer-300;

    font-weight: 600;
    color: $g-gray-500;

    @include media-breakpoint-up(xl) {
      margin-right: 0;
      padding: $g-spacer-300 $g-spacer-600;
      font-weight: $g-fw-bold;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      color: $g-gray-900;
      text-decoration: none;
    }

    &.active {
      border-bottom: 2px solid $g-color-primary;
      color: $g-gray-900;
    }
  }
}

// -----------------------------------------------------------------------------
// Tabbable tabs, copy from Bootstrap
// Hide tabbable panes to start, show them when `.active`
// -----------------------------------------------------------------------------

.tab-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}
