.button {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(1, 0, 40, 0.05);
  align-self: stretch;
  display: flex;
  gap: 8px;
  margin: auto 0;
  padding: 6px 16px;
  border: none;
  cursor: pointer;
}

.secondary {
  border: 1px solid var(--Color-Grey-400, #d0d0d6);
  background: var(--Color-Grey-000, #fff);
  color: var(--Color-Grey-900, #010028);
}

.primary {
  background: var(--Color-Button, linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%), #0D164F);
  color: rgba(255, 255, 255, 1);
}

.primary:disabled {
  background:var(--Color-Grey-100, #f9f9f9);
  color: var(--Color-Grey-500, #6e7272);
  border-color: var(--Color-Grey-300, #ccc);
  cursor: auto;
}

.primary:disabled .icon {
  filter: grayscale(1) brightness(0.5) saturate(3);
}

.label {
  align-self: stretch;
  margin: auto 0;
  font: 500 14px/1 Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
  line-height: 20px;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}
