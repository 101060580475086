/* stylelint-disable scss/at-rule-conditional-no-parentheses */

// -----------------------------------------------------------------------------
// A11Y - Focus visible
// -----------------------------------------------------------------------------

@mixin g-accessibility-focus($inset: true) {
  outline-width: $g-focus-outline-width;
  outline-style: dashed;

  @if ($inset) {
    outline-offset: -$g-focus-outline-width;
  } @else {
    outline-offset: $g-focus-outline-width;
  }
}
