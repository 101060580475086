.app-header {
  --header-offset-top: 0rem;

  display: flex;
  align-items: center;
  height: var(--g-header-height);
  z-index: $g-zindex-fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: var(--header-offset-top);
  /*padding-bottom: g-px-to-rem(4px);*/
  padding-left: $g-spacer-600;
  background-color: $g-color-white;

  /*@include media-breakpoint-up(lg) {*/
    padding-bottom: 0;
    border-bottom: $g-border-width solid $g-gray-200;
/*  }*/

  .mobile-page-view & {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__title {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $g-gray-500;

    &:hover {
      color: $g-gray-1000;
      text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
      border-left: $g-border-width solid $g-gray-200;
      padding: 0 $g-input-btn-padding-x;
    }
  }
}
