.item {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 8px;
  margin-top: 8px;
}

.itemChosen {
  background: #F5F4F7;
}

.item:first-child {
  margin-top: 0;
}

.title {
  text-align: start;
  align-self: stretch;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--Color-Grey-900, #010028);
}

.metaContainer {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
  color: var(--Color-Grey-800, #5a545c);
  line-height: 1;
  justify-content: start;
  gap: 5px;
}

.metaText {
  align-self: stretch;
  margin: auto 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.dotCenter {
  height: 4px;
  width: 4px;
  background-color: #4a4a4a;
  border-radius: 50%;
  display: inline-block;
}
