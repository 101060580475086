// -----------------------------------------------------------------------------
// Contact Data Details
// -----------------------------------------------------------------------------

.contact-details {
  display: flex;
  flex-direction: column;
  gap: var(--g-grid-gutter);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-block: var(--g-grid-gutter);
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--g-grid-gutter);

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__address {
    @include media-breakpoint-down(lg) {
      padding-block: var(--g-grid-gutter);
      border-top: $g-border-width solid $g-color-primary-200;
      border-bottom: $g-border-width solid $g-color-primary-200;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: flex-start;
      padding: $g-spacer-200 $g-spacer-700;
      border-left: $g-border-width solid $g-color-primary-200;
      border-right: $g-border-width solid $g-color-primary-200;
    }
  }

  &__email-phone {
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-block: $g-spacer-200;
    }
  }
}
