// -----------------------------------------------------------------------------
// Custom badges
// -----------------------------------------------------------------------------

.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100%;
  width: $g-icon-wrapper-size-base;
  height: $g-icon-wrapper-size-base;
}

.icon-circle-500 {
  width: calc(2 * $g-fs-500);
  height: calc(2 * $g-fs-500);
}

.icon-circle-800 {
  width: calc(2 * $g-fs-800);
  height: calc(2 * $g-fs-800);
}

.icon-circle-850 {
  width: calc(2.28 * $g-fs-800);
  height: calc(2.28 * $g-fs-800);
}

.icon-circle-900 {
  width: calc(2 * $g-fs-900);
  height: calc(2 * $g-fs-900);
}
