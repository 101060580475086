// -----------------------------------------------------------------------------
// Position
// -----------------------------------------------------------------------------

.p-absolute-left-center {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.p-absolute-right-center {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
