.button {
  align-self: stretch;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(1, 0, 40, 0.05);
  gap: 8px;
  flex: 1;
  padding: 10px 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.primary {
  background: var(--Color-Grey-900, #212529);
  color: var(--Color-White, #fff);
}

.secondary {
  border: 1px solid var(--Color-Grey-400, #d0d0d6);
  background: var(--Color-White, #fff);
  color: var(--Color-Grey-900, #212529);
}
