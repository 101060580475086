// -----------------------------------------------------------------------------
// Display utilities
// -----------------------------------------------------------------------------

.d-contents {
  display: contents;
}

.align-self-end {
  align-self: end;
}

.f-grow-1 {
  flex-grow: 1;
}
