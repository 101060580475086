@mixin g-icon-font-family() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font-family}' !important;
  speak: never;
  font-size: $icon-font-default-size;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
