// -----------------------------------------------------------------------------
// Badges
// -----------------------------------------------------------------------------

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  padding: $g-badge-padding-y $g-badge-padding-x;
  border-radius: var(--g-border-radius-small);
  font-size: $g-fs-10;
  text-align: center;
  color: $g-gray-900;
  background-color: $g-gray-100;
  border: $g-border-width solid $g-gray-200;
  line-height: 11px;
  letter-spacing: 1.5px;

  &.bg-warning {
    color: $g-color-warning;
    border-color: $g-color-warning-200;
    background: $g-color-warning-100 !important;
  }

  &.bg-success {
    color: $g-color-success;
    border-color: $g-color-success-200;
    background: $g-color-success-100 !important;
  }

  &.bg-info {
    color: $g-color-info;
    border-color: $g-color-info-200;
    background: $g-color-info-100 !important;
  }

  &.bg-danger {
    color: $g-color-danger;
    border-color: $g-color-danger-200;
    background: $g-color-danger-100 !important;
  }

  &.bg-dark {
    @include g-apply-text-style('medium-100');

    color: $g-color-white;
    border-color: $g-gray-1000;
  }

  &.bg-light {
    color: $g-gray-900;
    border-color: $g-gray-300;
    background: $g-color-white !important;
  }

  &.badge-time {
    @include g-apply-text-style('medium-100');

    flex-shrink: 0;
    width: 70px;
    padding-left: 0;
    padding-right: 0;
    border-radius: var(--g-border-radius);
    line-height: 14px;
    letter-spacing: 0;
  }
}

.badge-num {
  @include g-apply-text-style('medium-100');

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: $g-gray-100;
  border: $g-border-width solid $g-gray-200;
  width: 2rem;
  height: 2rem;

  &[data-variant='rank'] {
    @include g-apply-text-style('medium-200');

    width: 2.75rem;
    height: 1.5rem;
    border-radius: var(--g-border-radius-small);
    background-color: transparent;
    line-height: 1;
  }

  &[data-primary='true'] {
    background-color: $g-color-primary-100;
    border-color: $g-color-primary-300;
  }
}
