// -----------------------------------------------------------------------------
// Timeline component
// -----------------------------------------------------------------------------

.timeline {
  --g-flow-space: #{$g-spacer-600};

  &__date {
    display: flex;
    align-items: center;
    gap: $g-spacer-300;
    position: sticky;
    top: var(--g-header-height);
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: $g-color-white;

    &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      background-color: $g-gray-500;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      height: 10px;
      background: linear-gradient(
        0deg,
        rgba($g-color-white, 0),
        rgba($g-color-white, 1)
      );
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $g-spacer-300;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__item-panel {
    @include g-apply-text-style('medium-200');

    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $g-spacer-300;
    align-self: stretch;
    padding: $g-spacer-base var(--g-grid-gutter);
    border: $g-border-width solid $g-gray-300;
    border-radius: var(--g-border-radius);
    background-color: $g-color-white;

    &.timeline-warning {
      background-color: $g-gray-100;
    }

    &.timeline-note {
      padding: $g-spacer-150 var(--g-grid-gutter);
      background: $g-color-primary-100 url('../../../public/media/bg-notes.png');
      border-color: $g-color-primary-300;
      line-height: 1.125rem;
      transition: color $g-transition-property;

      &.is-active-hover {
        &:hover {
          color: $g-color-primary;
        }
      }
    }

    &.timeline-maintenance-note {
      padding: $g-spacer-150 var(--g-grid-gutter);
      background: $g-color-warning-150 url('../../../public/media/bg-notes.png');
      border-color: $g-color-warning-150;
      line-height: 1.125rem;
      transition: color $g-transition-property;
      color: $g-color-warning-500;

      &.is-active-hover {
        &:hover {
          color: $g-color-warning;
        }
      }
    }

    &.timeline-optout {
      padding: $g-spacer-150 var(--g-grid-gutter);
      background: $g-color-primary-100 url('../../../public/media/bg-notes.png');
      border-color: $g-color-primary-300;
      line-height: 1.125rem;
      transition: color $g-transition-property;

      &:hover {
        color: $g-color-primary;
      }

      &::after {
        @include g-add-icon-font('calendar-arrow');

        font-size: $g-fs-14;
        color: $g-color-primary;
      }
    }

    &.timeline-record {
      @include g-apply-text-style('h5');

      padding: $g-spacer-150 var(--g-grid-gutter);
      background: $g-gray-100 url('../../../public/media/bg-notes.png');
      line-height: 1.125rem;
      color: $g-gray-900;
      text-transform: uppercase;

      &::after {
        @include g-add-icon-font('info');

        font-size: $g-fs-base;
        color: $g-gray-700;
      }
    }
  }

  &__item-status {
    --g-grid-gutter: #{$g-spacer-base};

    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}
