// -----------------------------------------------------------------------------
// Links
// -----------------------------------------------------------------------------

.link-underlined {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
