// -----------------------------------------------------------------------------
// Cursor
// -----------------------------------------------------------------------------

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-pe-none {
  pointer-events: none !important;
}
