@use 'sass:math';

// -----------------------------------------------------------------------------
// Breadcrumb
// -----------------------------------------------------------------------------

.breadcrumb {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 0 0 $g-spacer-600;

  &::before {
    @include g-add-icon-font('breadcrumb');

    position: absolute;
    left: 0;
    top: math.div($g-spacer-100, 0.75);
    font-size: $g-fs-base;
    color: $g-gray-700;
  }
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  padding-left: $g-spacer-base;
  color: $g-gray-500;

  &::before {
    @include g-add-icon-font('next');

    margin-right: $g-spacer-base;
    font-size: $g-fs-200;
    color: $g-gray-500;
  }
}

.breadcrumb-link {
  padding: 0;
  font-size: $g-fs-300;
  color: currentcolor;

  &:disabled {
    color: $g-gray-900;
  }
}
