@mixin g-custom-scrollbar(
  $size: $g-scrollbar-size,
  $border-radius: $g-scrollbar-radius,
  $foreground-color: $g-scrollbar-color,
  $background-color: $g-scrollbar-bg
) {
  // For Firefox
  scrollbar-width: thin;
  scrollbar-color: $foreground-color $background-color;

  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    border-radius: $border-radius;
    background: $background-color;
  }
}
