// stylelint-disable rule-empty-line-before

// -----------------------------------------------------------------------------
// Custom buttons
// -----------------------------------------------------------------------------

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $g-fs-base;
  font-weight: $g-font-weight-semibold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $g-border-width solid transparent;
  border-radius: var(--g-border-radius-btn);
  transition: $g-btn-transition;
  padding: $g-input-btn-mobile-factor * $g-input-btn-padding-y
    $g-input-btn-mobile-factor * $g-input-btn-padding-x;

  @include media-breakpoint-up(xl) {
    padding: $g-input-btn-padding-y $g-input-btn-padding-x;
  }

  &[disabled]:not(.btn-link):not(.btn-label-icon) {
    &,
    &:hover {
      background: $g-gray-100;
      color: $g-gray-500;
      border-color: $g-gray-300;
    }
  }
}

.btn-primary {
  background: var(--g-color-primary) linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%) ;
  color: var(--g-color-primary-opposite, #fff);
  padding: $g-spacer-base $g-spacer-600;

  &:hover {
    background: $g-color-primary1;
  }
  &:focus {
    background: $g-color-primary2;
  }
}

.btn-secondary {
  &,
  & a {
    color: $g-gray-900;
    border-color: $g-gray-300;
    background-color: $g-color-white;

    &:hover,
    &:active,
    &:focus {
      border-color: $g-gray-500;
      text-decoration: none;
      color: $g-gray-900;
    }
  }
}

.btn-secondary-subtle {
  height: 2rem;
  border-radius: 0.625rem;
  border: 1px solid var(--Color-Grey-400, #D0D0D6);
  background: var(--Color-Grey-000, #FFF);
  box-shadow: 0px 2px 10px 0px rgba(1, 0, 40, 0.05);
  color: var(--Color-Grey-900, #010028);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.btn-light {
  &,
  &:active,
  &:focus {
    color: $g-gray-900;
    border-color: $g-gray-200;
    background-color: $g-gray-200;
  }

  &:hover {
    color: $g-color-white;
    border-color: $g-color-primary;
    background-color: $g-color-primary;
  }
}

.btn-white {
  &,
  &:active,
  &:focus {
    color: $g-color-primary;
    border-color: $g-color-white;
    background-color: $g-color-white;
  }

  &:hover {
    color: $g-color-white;
    border-color: $g-color-primary;
    background-color: $g-color-primary;
    text-decoration: none;
  }
}

.btn-outline-primary {
  &,
  &:active,
  &:focus {
    color: $g-color-primary;
    border-color: $g-color-primary;
  }

  &:hover {
    color: $g-color-white;
    background: $g-color-primary;
  }
}

.btn-close {
  border: 0;
  background-color: transparent;

  &::before {
    @include g-add-icon-font('close');

    font-size: 1.375rem;
    color: $g-gray-900;
  }
}

.btn-icon {
  &,
  &:focus {
    color: $g-gray-700;
    padding: $g-spacer-100;
  }

  &:hover,
  &:active {
    color: $g-color-primary;
  }
}

.btn-square {
  flex-shrink: 0;
  width: g-px-to-rem($g-btn-square-width);
  padding-left: 0;
  padding-right: 0;
}

.btn-label-icon {
  @include g-apply-text-style('medium-300');

  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;

  position: relative;
  z-index: 1;
  gap: $g-spacer-300;
  border: 0;
  padding: 0;
  color: $g-gray-700;

  [class^='icon'] {
    background-color: var(--g-color-primary-opposite, #fff);
    color: $g-gray-700;
    transition: $g-transition-base;

    &.bordered {
      border: $g-border-width solid $g-gray-300;
    }
  }

  &:hover,
  &:focus {
    color: var(--g-color-primary-opposite, #fff);
    text-decoration: none;

    [class^='icon'] {
      &.icon-circle {
        background-color: $g-gray-200;
      }
    }
  }

  &[disabled] {
    pointer-events: none;
    background-color: transparent;

    [class^='icon'] {
      background-color: $g-gray-300 !important;
    }
  }

  &.label-icon-clean {
    &::before {
      left: -$g-spacer-300;
    }

    [class^='icon'] {
      background-color: transparent;
      color: $g-color-primary;
    }

    &:hover,
    &:focus {
      [class^='icon'] {
        background-color: transparent;
        color: var(--g-color-primary-opposite, #fff);
      }
    }
  }

  &.text-primary {
    &:hover {
      color: #{$g-color-primary-300} !important;
      [class^='icon'] {
        color: #{$g-color-primary-300} !important;
      }
    }
  }
}

.btn.btn-link {
  border-color: transparent;
  color: var(--g-color-text-link);
  text-decoration: none;
  padding: 0;

  &:hover,
  &:focus {
    &:not([disabled]) {
      color: var(--g-color-text-link);
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.65;
  }
}

.btn-link-dark {
  @include g-apply-text-style('h5');

  color: $g-gray-900;
  text-transform: uppercase;

  &.btn-label-icon {
    .icon-circle {
      background-color: $g-color-black;
    }

    &:hover,
    &:focus {
      .icon-circle {
        background-color: $g-color-white;
      }
    }
  }
}

.btn-icon-link {
  padding: 0;
  color: $g-gray-700;

  &:focus,
  &:hover {
    color: $g-color-primary;
  }

  &:disabled {
    color: $g-gray-500;
  }
}

.btn-icon-link-underline {
  $root: &;

  [class^='icon-'] {
    text-decoration: none;
  }

  &__label {
    text-decoration: underline;

    #{$root}:hover & {
      text-decoration: none;
    }
  }
}

.btn-sm {
  padding: $g-spacer-150 $g-spacer-base;
  font-size: $g-fs-300;
}

.btn-expand {
  width: $g-spacer-500;
  flex-shrink: 0;
  justify-content: flex-start;

  &,
  &:focus {
    padding: 0;
  }
}

.btn-go-top {
  position: fixed;
  bottom: calc(2 * var(--g-grid-gutter));
  right: var(--g-grid-gutter);
  transition: opacity $g-transition-property;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.link {
  text-decoration: underline;
}
