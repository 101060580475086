.app-main {
  min-height: calc(100% - $g-header-height);
  height: calc(100% - $g-header-height);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--g-grid-gutter-y, 2rem);

  /*@include media-breakpoint-up(lg) {*/
    margin-left: $g-sidebar-width;
 /* }*/

  .mobile-page-view & {
    @include media-breakpoint-down(lg) {
      margin-top: 0;
      padding-bottom: $g-spacer-600;
    }
  }
}
