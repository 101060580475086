// -----------------------------------------------------------------------------
// Mobile data grid - mobile alternative for React Data Table Component
// -----------------------------------------------------------------------------

.data-grid {
  display: flex;
  column-gap: var(--g-grid-gutter);
  flex-wrap: wrap;
  padding: 0 var(--g-grid-gutter);
  background-color: $g-color-white;
  border-radius: var(--g-border-radius);
  box-shadow: $g-box-shadow-200;

  &:not(:last-of-type) {
    margin-bottom: var(--g-grid-gutter);
  }

  .data-grid-bordered & {
    border: $g-border-width solid $g-border-color;
  }

  &__item {
    @include g-apply-text-style('regular-200');

    width: 100%;
    padding-block: calc(var(--g-grid-gutter) / 2);
    word-break: break-word;
    color: $g-gray-700;

    @include media-breakpoint-up(sm) {
      width: calc(50% - calc(var(--g-grid-gutter) / 2));
    }

    @include media-breakpoint-up(lg) {
      width: calc(33% - calc(var(--g-grid-gutter) / 2));
    }

    &:not(:last-of-type) {
      border-bottom: $g-border-width solid $g-border-color;
    }
  }

  &__item-label {
    @include g-apply-text-style('h5');

    margin-bottom: $g-spacer-100;
    text-transform: uppercase;
    font-weight: $g-fw-medium;
  }

  &__footer {
    position: relative;
    width: 100%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -$g-border-width;
      left: 0;
      right: 0;
      height: $g-border-width;
      background-color: $g-border-color;
    }
  }
}
