.flow > * + * {
  margin-top: var(--g-flow-space, 2rem);
}

.flow-200 {
  --g-flow-space: #{$g-spacer-200};
}

.flow-300 {
  --g-flow-space: #{$g-spacer-300};
}

.flow-base {
  --g-flow-space: #{$g-spacer-base};
}

.flow-500 {
  --g-flow-space: #{$g-spacer-500};
}

.flow-horizontal > * + * {
  margin-left: var(--g-flow-horizontal-space, 1.5rem);
}

.flow-horizontal-600 {
  --g-flow-horizontal-space: #{$g-spacer-600};
}

.flow-horizontal-700 {
  --g-flow-horizontal-space: #{$g-spacer-700};
}

.flow-horizontal-800 {
  --g-flow-horizontal-space: #{$g-spacer-800};
}
