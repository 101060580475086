/* stylelint-disable selector-class-pattern, no-descending-specificity */

.rich-text-editor {
  .remirror-theme {
    .Mui-selected {
      background-color: $g-color-primary !important;
    }

    .ProseMirror {
      box-shadow: $g-border-color 0 0 0 1px !important;

      &:focus {
        box-shadow: $g-gray-500 0 0 0 1px !important;
      }
    }

    .remirror-table-insert-button,
    .remirror-table-delete-inner-button {
      line-height: 1;
    }
  }
}

.MuiPopperUnstyled-root {
  .Mui-selected {
    background-color: $g-color-primary !important;
  }
}
