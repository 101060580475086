// -----------------------------------------------------------------------------
// Accessibility - add visible focus
// -----------------------------------------------------------------------------

.user-is-tabbing {
  a,
  button,
  .btn,
  .form-control,
  .form-check-input {
    &:focus,
    &:active {
      @include g-accessibility-focus($inset: false);
    }
  }

  /* stylelint-disable  selector-class-pattern */
  .btn-icon,
  .dropdown-toggle,
  a.dropdown-item,
  a.list-group-item,
  a.app-side-nav__item,
  .rdt_TableCol_Sortable {
    &:focus,
    &:active {
      @include g-accessibility-focus($inset: true);
    }
  }
  /* stylelint-enable  selector-class-pattern */

  .filter-checkbox {
    input[type='checkbox'] {
      &:focus,
      &:active {
        & + label {
          @include g-accessibility-focus($inset: true);
        }
      }
    }
  }
}
