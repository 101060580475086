// -----------------------------------------------------------------------------
// Header notifications
// -----------------------------------------------------------------------------
$header-notifications-toggle-width: g-px-to-rem(84px);

.header-notifications {
  height: 100%;

  @include media-breakpoint-up(xl) {
    border-left: $g-border-width solid $g-gray-200;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: $header-notifications-toggle-width;
    line-height: 1;
    color: $g-gray-500;
    animation: opacity-in-out 2s ease-in-out infinite;
  }

  &__toggle {
    height: 100%;
    border-radius: 0;
    border: 0;
    color: $g-gray-500;

    &::after {
      display: none;
    }

    &:hover {
      color: $g-gray-1000;
    }
  }

  &__bell {
    &.is-unread {
      position: relative;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -5px;
        right: -2px;
        width: 12px;
        height: 12px;
        border: 3px solid $g-color-white;
        border-radius: 10px;
        background-color: $g-color-danger;
        animation: bounce-in 1s ease-in-out forwards;
      }
    }
  }

  &__panel {
    &.dropdown-menu {
      @include g-custom-scrollbar;

      overflow-y: auto;
      width: calc(
        $g-dropdown-user-menu-width + $header-notifications-toggle-width
      );
      margin-top: -1px;
      padding: 0 $g-spacer-700;
      border: 0;
    }
  }

  &__item {
    display: flex;
    gap: $g-spacer-base;
    padding: $g-spacer-base 0;
    color: $g-gray-700;

    &:not(:last-child) {
      border-bottom: $g-border-width solid $g-gray-200;
    }

    &.dropdown-item {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__item-time-stamp {
    @include g-apply-text-style('regular-200');

    flex-shrink: 0;
    width: g-px-to-rem(85px);
    line-height: 1.42;
  }

  &__item-title {
    @include g-apply-text-style('medium-200');

    white-space: pre-wrap;
  }

  &__item-footer {
    text-align: right;
  }
}
