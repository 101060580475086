.navigation {
    display: flex;
    max-width: 316px;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    text-align: center;
    justify-content: start;
    align-self: start;
}
