.editor-wrapper {
  background: white;
  border-radius: 8px;
  border: 1px solid #3C3C46;
  overflow: hidden;
  padding: 0 10px;
}

.editor-main {
  padding: 10px 8px;
}

.rdw-editor-toolbar.editor-toolbar {
  padding: 8px;
  border-top: none;
  border-bottom: 1px solid #D9D9D9;
  border-left: none;
  border-right: none;
  margin-bottom: 0;
  .rdw-inline-wrapper {
    margin-bottom: 0;
  }
  .rdw-list-wrapper {
    margin-bottom: 0;
  }
  .rdw-history-wrapper {
    margin-bottom: 0;
  }
  .rdw-option-wrapper {
    border: none;
  }
}
