/* stylelint-disable scss/no-global-function-names, scss/at-rule-conditional-no-parentheses */

@mixin g-add-icon-font($icon: 'chart') {
  $char: map-get($icons-list, $icon);

  @if ($char) {
    @include g-icon-font-family;

    content: $char;
  } @else {
    @warn "--- SCSS error: no such character in font map ---";
  }
}
