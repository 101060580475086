// -----------------------------------------------------------------------------
// Generate additional color utilities
// -----------------------------------------------------------------------------

@mixin g-colors-utilities($colors) {
  @each $name, $value in $colors {
    .text-#{$name} {
      color: $value !important;
    }

    .bg-#{$name} {
      background-color: $value !important;
    }

    .border-#{$name} {
      border-color: $value !important;
    }
  }
}
