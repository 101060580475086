// -----------------------------------------------------------------------------
// CSS Animations
// -----------------------------------------------------------------------------

@keyframes opacity-in-out {
  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce-in {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.6, 1.6, 1.6);
  }

  40% {
    transform: scale3d(0.7, 0.7, 0.7);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1.2);
  }

  80% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
