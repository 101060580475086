.chip {
  align-self: stretch;
  border-radius: 5px;
  cursor: pointer;
  gap: 10px;
  margin: auto 0;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 400;
}

.active {
  background: var(--Color-Grey-200, #f5f4fc);
  color: var(--Color-Grey-900, #010028);
  font-weight: 500;
}

.disabled {
  background: var(--Color-Grey-100, #f5f4f7);
  color: var(--Color-Grey-700, #797980);
}
