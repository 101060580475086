// -----------------------------------------------------------------------------
// Bootstrap Carousel
// -----------------------------------------------------------------------------

.carousel-indicators {
  bottom: auto;
  top: 50px;

  [data-bs-target] {
    border: $g-border-width solid $g-gray-300;
    border-radius: 50px;
  }

  .active {
    border-color: $g-gray-900;
    background-color: $g-gray-900;
  }
}

.carousel-item {
  padding-bottom: 2px;

  &__title {
    height: 85px;
    text-align: center;
  }
}
