/* stylelint-disable no-descending-specificity, scss/no-global-function-names, no-duplicate-selectors */
// -----------------------------------------------------------------------------
// Tooltip Bootstratp
// -----------------------------------------------------------------------------

.tooltip {
  &:not(.tooltip-dark) {
    .tooltip-inner {
      border-radius: var(--g-border-radius, 6px);
      box-shadow: 0 0 0 $g-border-width $g-gray-300 inset;
    }

    .tooltip-arrow {
      &::after {
        position: absolute;
        content: '';
        border-color: transparent;
        border-style: solid;
        z-index: -1;
      }
    }
  }
}

.bs-tooltip-start {
  .tooltip-arrow {
    &::after {
      border-width: ($tooltip-arrow-width * 0.5) 0 ($tooltip-arrow-width * 0.5)
        $tooltip-arrow-height;
      border-left-color: darken($g-gray-300, 15deg);
    }
  }
}

.bs-tooltip-end {
  .tooltip-arrow {
    &::after {
      border-width: ($tooltip-arrow-width * 0.5) $tooltip-arrow-height
        ($tooltip-arrow-width * 0.5) 0;
      border-right-color: darken($g-gray-300, 15deg);
    }
  }
}

.bs-tooltip-top {
  .tooltip-arrow {
    &::after {
      border-width: $tooltip-arrow-height ($tooltip-arrow-width * 0.5) 0;
      border-top-color: darken($g-gray-300, 15deg);
    }
  }
}

.bs-tooltip-bottom {
  .tooltip-arrow {
    &::after {
      border-width: 0 ($tooltip-arrow-width * 0.5) $tooltip-arrow-height;
      border-bottom-color: darken($g-gray-300, 15deg);
    }
  }
}

// -----------------------------------------------------------------------------
// Dark tooltip
// -----------------------------------------------------------------------------
.tooltip {
  &.tooltip-dark {
    .tooltip-inner {
      box-shadow: none;
      background-color: $g-gray-900;
      color: $g-gray-200;
    }

    &.bs-tooltip-start {
      .tooltip-arrow {
        &::before {
          border-left-color: $g-gray-900;
        }
      }
    }

    &.bs-tooltip-end {
      .tooltip-arrow {
        &::before {
          border-right-color: $g-gray-900;
        }
      }
    }

    &.bs-tooltip-top {
      .tooltip-arrow {
        &::before {
          border-top-color: $g-gray-900;
        }
      }
    }

    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &::before {
          border-bottom-color: $g-gray-900;
        }
      }
    }
  }

  &.tooltip-wide {
    .tooltip-inner {
      @include media-breakpoint-up(xl) {
        max-width: 700px;
      }
    }
  }
}
