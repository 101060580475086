// -----------------------------------------------------------------------------
// Links
// -----------------------------------------------------------------------------

.link-more {
  position: relative;
  padding-right: $g-spacer-500;

  &::after {
    @include g-add-icon-font('chevron-right');

    font-size: 0.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.link-chevron {
  padding-right: $g-spacer-500;
  background: url('../../../public/media/chevron.svg') right no-repeat;
}
