// -----------------------------------------------------------------------------
// Style Bootstrap alerts
// -----------------------------------------------------------------------------

.alert {
  display: flex;
  align-items: center;
  position: relative;
  padding: $g-spacer-base;
  margin-bottom: $g-spacer-base;
  border: $g-border-width solid transparent;
  border-radius: var(--g-border-radius);
  background-color: $g-color-white;
  box-shadow: $g-box-shadow;

  &::before {
    margin-right: var(--g-grid-gutter);
  }

  .btn-close {
    position: absolute;
    top: 50%;
    right: calc(var(--g-grid-gutter) / 2);
    transform: translateY(-50%);
    z-index: 2;
    padding: $g-spacer-100;

    &::before {
      @include g-add-icon-font('clear');

      font-size: 0.625rem;
      color: $g-body-color;
    }
  }
}

.alert-danger {
  &::before {
    @include g-add-icon-font('warning');

    color: $g-color-danger;
  }

  border-color: $g-color-danger-200;
  background: $g-color-white
    linear-gradient($g-color-danger-100, $g-color-danger-100);
}

.alert-success {
  &::before {
    @include g-add-icon-font('success');

    color: $g-color-success;
  }

  border-color: $g-color-success-200;
  background: $g-color-white
    linear-gradient($g-color-success-100, $g-color-success-100);
}

.alert-warning {
  &::before {
    @include g-add-icon-font('warning');

    color: $g-color-warning;
  }

  border-color: $g-color-warning-200;
  background: $g-color-white
    linear-gradient($g-color-warning-100, $g-color-warning-100);
}

.alert-info {
  &::before {
    @include g-add-icon-font('success');

    color: $g-color-info;
  }

  border-color: $g-color-info-200;
  background: $g-color-white
    linear-gradient($g-color-info-100, $g-color-info-100);
}

.alert-simple {
  border-color: $g-gray-200;
}
