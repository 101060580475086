@use 'sass:map';

:root {
  // Overwrite BS variables
  --bs-body-font-size: #{$g-fs-base};

  // Base
  --g-grid-gutter: 1.5rem;
  --g-grid-gutter-y: 1.5rem;
  --g-flow-space: var(--g-grid-gutter);
  --g-layout-breakpoint: #{calc(map.get($grid-breakpoints, 'xl') - 1px)};
  --g-layout-breakpoint-lg: #{calc(map.get($grid-breakpoints, 'lg') - 1px)};
  --g-flow-horizontal-space: calc(0.65 * var(--g-grid-gutter));
  --g-border-radius: 8px;
  --g-border-radius-btn: 10px;
  --g-border-radius-small: 3px;
  --g-border-radius-large: 12px;
  --g-border-color: #{$g-border-color};
  --g-header-height: #{$g-header-height-mobile};

  // Colors
  // Theme specific
  --g-color-primary: #0D164F;
  --g-color-primary-rgb: 0 112 141;
  --g-color-primary-hue: 192.3404255319149;
  --g-color-primary-saturation: 100%;
  --g-color-primary-lightness: 27.647058823529413%;
  --bs-primary: #00708d;
  --bs-primary-rgb: 0, 112, 141;
  --g-color-primary-type: dark;
  // Theme specific
  --g-color-primary-opposite: #{$g-color-white};
  --g-color-text-link: var(--g-color-primary);
  --g-color-link: #{$g-gray-900};
  --g-gray-100: #{$g-gray-100};
  --g-gray-200: #{$g-gray-200};
  --g-gray-300: #{$g-gray-300};
  --g-gray-500: #{$g-gray-500};
  --g-gray-700: #{$g-gray-700};
  --g-gray-900: #{$g-gray-900};
  --g-gray-1000: #{$g-gray-1000};
  --g-gray-bg: #{$g-gray-bg};

  // Spacers
  --g-spacer-100: #{$g-spacer-100};
  --g-spacer-200: #{$g-spacer-200};
  --g-spacer-300: #{$g-spacer-300};
  --g-spacer-base: #{$g-spacer-base};
  --g-spacer-500: #{$g-spacer-500};
  --g-spacer-600: #{$g-spacer-600};
  --g-spacer-700: #{$g-spacer-700};
  --g-spacer-800: #{$g-spacer-800};
  --g-spacer-900: #{$g-spacer-900};

  // font sizes
  --g-fs-8: #{$g-fs-8};
  --g-fs-10: #{$g-fs-10};
  --g-fs-12: #{$g-fs-12};
  --g-fs-14: #{$g-fs-14};
  --g-fs-16: #{$g-fs-16};
  --g-fs-18: #{$g-fs-18};
  --g-fs-20: #{$g-fs-20};
  --g-fs-200: #{$g-fs-200};
  --g-fs-300: #{$g-fs-300};
  --g-fs-base: #{$g-fs-base};
  --g-fs-500: #{$g-fs-500};
  --g-fs-600: #{$g-fs-600};
  --g-fs-700: #{$g-fs-700};
  --g-fs-800: #{$g-fs-800};
  --g-fs-900: #{$g-fs-900};

  // font weight
  --g-fw-regular: #{$g-fw-regular};
  --g-fw-medium: #{$g-fw-medium};
  --g-fw-bold: #{$g-fw-bold};

  // Components
  --g-rdt-controls-height: #{$g-rdt-controls-height};

  // Table
  --g-table-cell-padding-y: #{$g-table-cell-padding-y};
  --g-table-cell-padding-x: #{$g-table-cell-padding-x};
  --g-table-cell-width-default: #{$g-table-cell-width-default};
  --g-table-cell-width-wide: #{$g-table-cell-width-wide};
  --g-table-cell-min-width: 30px;
  --g-table-cell-only-icon-width: 50px;

  @include media-breakpoint-up(xl) {
    --g-header-height: #{$g-header-height};
  }

  @include media-breakpoint-up(xxl) {
    --g-grid-gutter-y: 2rem;
    --g-grid-gutter: 2rem;
  }
}
