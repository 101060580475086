// -----------------------------------------------------------------------------
// Icon font from Icomoon
// -----------------------------------------------------------------------------
@font-face {
  font-family: '#{$icon-font-family}';
  src:
    url('#{$icon-font-path}/#{$icon-font-family}.ttf?o0xtn8') format('truetype'),
    url('#{$icon-font-path}/#{$icon-font-family}.woff?o0xtn8') format('woff'),
    format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  @include g-icon-font-family;
}

// -----------------------------------------------------------------------------
// Generate icons classes
// -----------------------------------------------------------------------------

@each $icon-name, $icon-char in $icons-list {
  .icon-#{$icon-name}::before {
    content: $icon-char;
  }
}
