// -----------------------------------------------------------------------------
// Styling Bootstrap spinner
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Extensions
// -----------------------------------------------------------------------------

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $spinner-min-height;
}
