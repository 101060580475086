.dialog {
  border-radius: 16px;
  background: var(--Color-White, #fff);
  display: flex;
  max-width: 400px;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  font: 16px Uncut Sans, sans-serif;
}

.title {
  color: #191919;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
}

.message {
  color: #191919;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.buttonContainer {
  align-self: center;
  display: flex;
  margin-top: 16px;
  width: 100%;
  max-width: 320px;
  gap: 16px;
  letter-spacing: 0.02px;
}
