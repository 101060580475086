.settingsContainer {
    align-items: end;
    align-self: stretch;
    background: var(--Color-Grey-100, #f5f4f7);
    display: flex;
    max-width: 600px;
    flex-direction: column;
    font-family: Uncut Sans, sans-serif;
    padding: 24px 24px 421px;
}

.dropdownContainer {
    width: 50%;
}

.title {
    color: var(--Color-Grey-900, #212529);
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    align-self: start;
    margin: 0 0 24px;
}

.settingRow {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E2E2F0;
}

.settingDescription {
    flex: 1;
}

.settingTitle {
    color: var(--Color-Grey-900, #212529);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.settingSubtext {
    color: var(--Color-Grey-700, #6a6d70);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
}

.dropdownControl {
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border: 1px solid #ccc;
    min-width: 44px;
}

.dropdownValue {
    color: var(--Color-Grey-900, #212529);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    flex: 1;
}

.dropdownIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.segmentedButtons {
    display: flex;
    align-items: center;
    gap: -1px;
}

.segmentBtn {
    border: 1px solid var(--Color-Grey-400, #d0d0d6);
    background: var(--Color-Grey-000, #fff);
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--Color-Grey-900, #212529);
    cursor: pointer;
}

.segmentBtn.active {
    background-color: #e7e8ed;
    color: var(--Color-Button, #0d164f);
}

.leftBtn {
    border-radius: 10px 0 0 10px;
}

.rightBtn {
    border-radius: 0 10px 10px 0;
}

.divider {
    width: 100%;
    height: 1px;
    margin: 24px 0;
    object-fit: contain;
}

@media (max-width: 991px) {
    .settingsContainer {
        padding: 0 20px 100px;
    }

    .settingRow {
        display: none;
    }
}
