// -----------------------------------------------------------------------------
// Summary Events item
// -----------------------------------------------------------------------------

.events-summary {
  --spacer: calc(1.65 * var(--g-grid-gutter));
  $root: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacer);
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;

    + #{$root}__item {
      @include media-breakpoint-up(xl) {
        border-left: $g-border-width solid $g-border-color;
        padding-left: var(--spacer);
      }
    }
  }

  &__item-label {
    display: flex;
    align-items: flex-end;
    margin-left: var(--spacer);
  }
}
