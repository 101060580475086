// -----------------------------------------------------------------------------
// Offcanvas - Bootstrap component
// -----------------------------------------------------------------------------

.offcanvas {
  &[data-variant='more'] {
    z-index: $g-zindex-offcanvas-more;
    box-shadow: $g-box-shadow-400;
    border-top-left-radius: var(--g-border-radius);
    border-top-right-radius: var(--g-border-radius);
    bottom: $g-bottom-navigation-height;
  }
}

.offcanvas-header {
  height: var(--g-header-height);

  .btn-close {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;

    &::before {
      @include g-add-icon-font('hamburger');

      font-size: 1.25rem;
    }
  }
}
