/* stylelint-disable max-nesting-depth, scss/dollar-variable-pattern, value-keyword-case */

// -----------------------------------------------------------------------------
// Fluid typography fontsizes  320px - 1440px (Figma Design) - 1940px generate by https://utopia.fyi/
// -----------------------------------------------------------------------------
$g-fs-8: 0.5rem; // 8px
$g-fs-10: 0.625rem; // 10px
$g-fs-12: 0.75rem; // 12px
$g-fs-14: 0.875rem; // 14px
$g-fs-16: 1rem; // 16px
$g-fs-18: 1.125rem; // 18px
$g-fs-20: 1.25rem; // 20px

$g-fs-200: clamp(0.63rem, 0.59rem + 0.18vw, 0.75rem); // 10px - 12px
$g-fs-300: clamp(0.75rem, 0.71rem + 0.18vw, 0.88rem); // 12px - 14px
$g-fs-base: clamp(0.88rem, 0.84rem + 0.18vw, 1rem); // 14px - 16px
$g-fs-500: clamp(1rem, 0.96rem + 0.18vw, 1.13rem); // 16px - 18px
$g-fs-600: clamp(1rem, 0.93rem + 0.36vw, 1.25rem); // 16px - 20px
$g-fs-700: clamp(1.13rem, 1.02rem + 0.54vw, 1.5rem); // 18px - 24px
$g-fs-800: clamp(1.38rem, 1.27rem + 0.54vw, 1.75rem); // 22px - 28px
$g-fs-900: clamp(1.75rem, 1.61rem + 0.71vw, 2.25rem); // 28px - 36px - 38px

// -----------------------------------------------------------------------------
// Font weights
// -----------------------------------------------------------------------------

$g-fw-regular: 400;
$g-fw-medium: 500;
$g-fw-semi-bold: 600;
$g-fw-bold: 700;

// -----------------------------------------------------------------------------
// Global definition of all text styles - headings, medium, regular
// -----------------------------------------------------------------------------
// Headers 36, 28, 24, 20, 12

$g-text-styles: (
  h1: (
    defaultStyles: (
      font-size: $g-fs-900,
      line-height: 1.1,
      -webkit-font-smoothing: antialiased,
      -moz-osx-font-smoothing: grayscale,
    ),
  ),
  h2: (
    defaultStyles: (
      font-size: $g-fs-800,
      line-height: 1.3,
      -webkit-font-smoothing: antialiased,
      -moz-osx-font-smoothing: grayscale,
    ),
  ),
  h3: (
    defaultStyles: (
      font-size: $g-fs-700,
      line-height: 1.4,
      -webkit-font-smoothing: antialiased,
      -moz-osx-font-smoothing: grayscale,
    ),
  ),
  h4: (
    defaultStyles: (
      font-size: $g-fs-18,
      line-height: 1.5,
      -webkit-font-smoothing: antialiased,
      -moz-osx-font-smoothing: grayscale,
    ),
  ),
  h5: (
    defaultStyles: (
      font-size: $g-fs-12,
      line-height: 1.5,
      letter-spacing: 2.5px,
      color: $g-gray-500,
      font-weight: $g-fw-medium,
    ),
  ),
  medium-100: (
    defaultStyles: (
      font-size: $g-fs-200,
      line-height: 1.5,
      font-weight: $g-fw-medium,
    ),
  ),
  medium-200: (
    defaultStyles: (
      font-size: $g-fs-300,
      line-height: 1.42,
      font-weight: $g-fw-medium,
    ),
  ),
  medium-200-semi-bold: (
    defaultStyles: (
      font-size: $g-fs-300,
      line-height: 1.42,
      font-weight: $g-fw-semi-bold,
      -webkit-font-smoothing: antialiased,
      -moz-osx-font-smoothing: grayscale,
    ),
  ),
  medium-300: (
    defaultStyles: (
      font-size: $g-fs-base,
      line-height: 1.5,
      font-weight: $g-fw-medium,
    ),
  ),
  medium-300-semi-bold: (
    defaultStyles: (
      font-size: $g-fs-base,
      line-height: 1.5,
      font-weight: $g-fw-semi-bold,
      -webkit-font-smoothing: antialiased,
      -moz-osx-font-smoothing: grayscale,
    ),
  ),
  medium-400: (
    defaultStyles: (
      font-size: $g-fs-500,
      line-height: 1.55,
      font-weight: $g-fw-medium,
    ),
  ),
  medium-500: (
    defaultStyles: (
      font-size: $g-fs-600,
      line-height: 1.6,
      font-weight: $g-fw-medium,
    ),
  ),
  regular-100: (
    defaultStyles: (
      font-size: $g-fs-200,
      line-height: 1.66,
    ),
  ),
  regular-200: (
    defaultStyles: (
      font-size: $g-fs-300,
      line-height: 1.86,
    ),
  ),
  regular-300: (
    defaultStyles: (
      font-size: $g-fs-base,
      line-height: 1.5,
    ),
  ),
  regular-300-gray-700: (
    defaultStyles: (
      font-size: $g-fs-base,
      line-height: 1.55,
      color: $g-gray-700,
    ),
  ),
  regular-400: (
    defaultStyles: (
      font-size: $g-fs-500,
      line-height: 1.55,
    ),
  ),
  regular-500: (
    defaultStyles: (
      font-size: $g-fs-600,
      line-height: 2.25,
      color: $g-gray-500,
    ),
  ),
  regular-600: (
    defaultStyles: (
      font-size: $g-fs-200,
      line-height: 1.6,
      letter-spacing: 4px,
      color: $g-gray-500,
    ),
  ),
);
