.app-side-nav {
  $root: &;

  /*@include media-breakpoint-up(lg) {*/
    border-right: $g-border-width solid $g-gray-200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    top: var(--g-header-height);
    width: $g-sidebar-width;
 /* }*/

  .app-side-nav__svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $nav-icon-wrapper-size;
    height: $nav-icon-wrapper-size;
    border-radius: var(--g-border-radius, 0);
    transition: $g-transition-base;

    .bottom-navigation & {
      width: 30px;
      height: 30px;
      font-size: $g-fs-800;
      margin-bottom: $g-spacer-100;
    }

    .more-menu & {
      width: 20px;
      margin-right: $g-spacer-500;
    }
  }

  &__icon {
    color: var(--app-side-nav-icon-color);

    &.disabled {
      color: $g-gray-300;
    }

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $nav-icon-wrapper-size;
      height: $nav-icon-wrapper-size;
      border-radius: var(--g-border-radius, 0);
      transition: $g-transition-base;

      .bottom-navigation & {
        width: 30px;
        height: 30px;
        font-size: $g-fs-800;
        margin-bottom: $g-spacer-100;
      }

      .more-menu & {
        width: 20px;
        margin-right: $g-spacer-500;
      }
    }

    &.icon-car-strong {
      &::before {
        font-size: 1.3rem;
      }
    }
  }

  &__item {
    --app-side-nav-icon-color: #{$g-gray-700};
    $stripe-transition-duration: 0.2s;

    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    color: $g-gray-700;
    transition: $g-transition-base;
    align-items: center;

    /*@include media-breakpoint-up(lg) {*/
      justify-content: center;
      width: 100%;
      padding-top: $g-spacer-300;
      padding-bottom: $g-spacer-300;
    /*}*/

    .app-side-nav__item-label {
      font-size: $g-fs-300;
      margin-top: $g-spacer-50;
    }

    &:hover,
    &--active {
      --app-side-nav-icon-color: #{$g-color-primary};
      color: $g-color-primary;

      text-decoration: none;



      .app-side-nav__svg-container {
        background-color: var(--Color-Button, #0D164F);
      }

      @include media-breakpoint-up(lg) {
        svg path {
          fill: $g-color-white;
        }
        #{$root}__icon {
          &::before {
            background-color: $g-color-primary-100;
          }
        }
      }
    }

    &--active {
      /*@include media-breakpoint-up(lg) {*/
        &::after {
          opacity: 1;
          transition-delay: $stripe-transition-duration;
        }
     /* }*/
    }

    .bottom-navigation & {
      @include g-apply-text-style('regular-100');

      position: relative;
      flex-direction: column;
      flex-grow: 1;
      padding-top: $g-spacer-300;

      &::after {
        content: '';
        position: absolute;
        display: block;
        inset: 0 $g-spacer-200 auto $g-spacer-200;
        height: 4px;
        background-color: $g-color-primary;
        border-radius: var(--g-border-radius);
        transition: opacity $stripe-transition-duration;
        opacity: 0;
      }

      &--active {
        &::after {
          opacity: 1;
        }
      }
    }

    .more-menu & {
      &:not(:last-child) {
        border-bottom: $g-border;
      }
    }

    &.btn-more-menu-toggle {
      --app-side-nav-icon-color: #{$g-gray-700};

      justify-content: flex-start;
      padding-inline: 0;
      padding-bottom: 0;
      border: 0;

      &.is-more-menu-open {
        --app-side-nav-icon-color: #{$g-color-primary};
      }
    }

    &.disabled {
      /*@include media-breakpoint-up(lg) {*/
        #{$root}__icon {
          &::before {
            background-color: unset;
          }
        }
      /*}*/
    }
  }

  &__item-label {
    .bottom-navigation & {
      padding-inline: $g-spacer-200;
      text-align: center;
      line-height: 1;
    }
  }
}

.bottom-navigation {
  display: flex;
  position: fixed;
  z-index: $g-zindex-app-bottom-navigation;
  bottom: 0;
  left: 0;
  right: 0;
  height: $g-bottom-navigation-height;
  background-color: $g-color-white;
  box-shadow: $g-box-shadow-400;
  border-top-left-radius: var(--g-border-radius);
  border-top-right-radius: var(--g-border-radius);

  .mobile-page-view & {
    display: none;

    /*@include media-breakpoint-up(lg) {*/
      display: flex;
    /*}*/
  }
}
