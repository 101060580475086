.container {
  align-self: stretch;
  background: var(--Color-Grey-000, #fff);
  background-color: var(--Color-Grey-000, #fff);
  display: flex;
  flex-direction: column;
  font-family: Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
  justify-content: start;
}
