.report {
  align-items: center;
  align-self: stretch;
  background: var(--Color-Grey-100, #f5f4f7);
  display: flex;
  max-width: 729px;
  flex-direction: column;
  justify-content: start;
}

.reportContent {
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  justify-content: start;
  gap: 24px;
}

