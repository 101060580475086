// Charts colors
:root {
  --g-color-chart-high: #{$g-color-danger};
  --g-color-chart-high-light: #{$g-color-warning};
  --g-color-chart-medium: #{$g-color-success};
  --g-color-chart-low: #{$g-color-info};
  --g-color-chart-tooltip-bg: #{$g-gray-900};
}

.chart-legend {
  &__color-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__color-item {
    @include g-apply-text-style('regular-200');

    display: flex;
    align-items: baseline;
    flex-grow: 1;
    width: 50%;
    color: $g-gray-700;
    line-height: 1;
    margin-top: $g-spacer-300;

    &::before {
      content: '';
      flex-shrink: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-width: 3px;
      border-style: solid;
      border-radius: 10px;
      margin-right: $g-spacer-300;
    }

    &[data-color='high'] {
      &::before {
        border-color: var(--g-color-chart-high);
      }
    }

    &[data-color='high-light'] {
      &::before {
        border-color: var(--g-color-chart-high-light);
      }
    }

    &[data-color='medium'] {
      &::before {
        border-color: var(--g-color-chart-medium);
      }
    }

    &[data-color='low'] {
      &::before {
        border-color: var(--g-color-chart-low);
      }
    }
  }
}
