// -----------------------------------------------------------------------------
// Landing page
// -----------------------------------------------------------------------------

.app-landing-page {
  display: flex;
  background-color: $g-color-white;
  overflow-y: auto;

  &__content-wrapper {
    display: flex;
    width: 90rem;
    padding: 6.75rem 0rem 0rem 0rem;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    width: 17.75rem;
    flex-direction: column;
    align-items: center;
    gap: 2.25rem;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__content-text-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 13.75rem;
    padding: 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    
  }

  &__content-title {    
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    align-self: stretch;
  }

  &__content-subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--Color-Grey-700, #797980);
    align-self: stretch;
  }

  &__content-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
  }

  &__content-signup-button {
    display: flex;
    height: 3.5rem;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
  }

  &__content-signin-button {
    display: flex;
    height: 3.5rem;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: var(--200, 0.5rem);
    align-self: stretch;
  }
}
