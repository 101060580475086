// -----------------------------------------------------------------------------
// More Menu
// -----------------------------------------------------------------------------

.more-menu {
    position: relative;

    &__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      padding: 0.25rem;
      cursor: pointer;
      border-radius: 0.25rem;
      color: var(--Color-Grey-700);

      &:hover {
        background: var(--Color-Grey-100);
      }
    }

    &__content {
      position: absolute;
      display: flex;
    padding: 0.8125rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11), 0px 8px 20px 0px rgba(75, 90, 99, 0.13);
      z-index: 100;
    }
  }

  .menu-item {
    display: flex;
    min-width: 7.5rem;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    background: #FFF;
    justify-content: left;


    &__label {
        color: #021A27;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        word-break: keep-all;
        width: max-content;
    }

    &:hover {
      background-color: var(--Color-Grey-100);
    }

    &--danger {
      color: var(--Color-Red-500);
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
  }
