// -----------------------------------------------------------------------------
// Additional borders utilities
// -----------------------------------------------------------------------------

.horizontal-borders > * + * {
  border-left: 1px solid $g-border-color;
}

.border-gray-200-light {
  border-color: rgba($g-border-color, 0.2) !important;
}
