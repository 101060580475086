// -----------------------------------------------------------------------------
// Settings page
// -----------------------------------------------------------------------------

.settings {
  &-head {
    display: flex;
    align-items: center;
    height: g-px-to-rem(50px);
    min-height: g-px-to-rem(50px);
    font-size: $g-fs-300;
    font-weight: $g-font-weight-bold;
    padding: 0 $g-spacer-600;
    border-bottom: $g-border-width solid $g-gray-200;
  }

  &-left-column {
    flex-shrink: 0;
    width: g-px-to-rem(316px);
    border-right: $g-border-width solid $g-gray-200;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $g-spacer-300 $g-spacer-600;
      gap: $g-spacer-300;
      height: calc(100% - 50px);
      overflow-y: auto;
    }

    &.collapsed {
      .collapsable {
        display: none;
      }
      width: 72px !important;
    }
  }

  &-navigation {
    width: 100%;
  }

  &-nav-item {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    align-self: stretch;
    border-radius: 0.625rem;
    background: #fff;
    color: var(--Color-Grey-800, #5a545c);
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;

    &.settings-nav-item-active {
      background: #f5f4fc;
      justify-content: space-between;
      color: #010028;
      font-weight: 500;
    }

    &.settings-nav-item-disabled {
      opacity: 0.5;
    }
  }
}

.settings-content-wrapper {
  display: flex;
  align-self: stretch;
  font-family:
    Uncut Sans,
    sans-serif;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-right: 1px solid #d9d9d9;
  background: var(--Color-Grey-100, #f5f4f7);
  overflow-y: auto;
}

.settings-content {
  display: flex;
  max-width: 600px;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}

.settings-section {
  max-width: g-px-to-rem(800px);
  margin: 0 auto;
}

.settings-title {
  color: var(--Color-Grey-900, #010028);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  font-style: normal;
  line-height: 2rem;
}

.settings-section-title {
  color: var(--Color-Grey-700, #6A6D70);
  font-family: "Uncut Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 2.24px;
  text-transform: uppercase;
}

.settings-row {
  display: flex;
  min-height: 44px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: $g-gray-450;
  font-size: 12px;
  font-weight: $g-font-weight-normal;
  padding-bottom: 1.5rem;
  gap: 1rem;
  border-bottom: $g-border-width solid $g-gray-200;

  &:last-child {
    border-bottom: none;
  }

  .description {
    .title {
      color: var(--Color-Grey-900, #212529);
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  .w-70 {
    width: 70%;
  }
  .w-50 {
    width: 50%;
  }

  .link {
    color: #000;
    display: block;
    font-family: Uncut Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}

.settings-radio-option {
  margin: $g-spacer-500 0;
}

.mirror-x {
  transform: scaleX(-1);
}

.settings-top-link {
  font-size: $g-fs-14;
  font-weight: $g-font-weight-semibold;
  color: $g-gray-1000;
  padding: $g-spacer-150 $g-spacer-base $g-spacer-150 0;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
