// -----------------------------------------------------------------------------
// Accordion
// -----------------------------------------------------------------------------

.accordion-static {
  border: $g-border;
  background-color: $g-color-white;

  &:not(:first-of-type) {
    margin-top: 0;
    border-top: 0;
  }
}

.accordion {
  .accordion-item {
    border: 0;
    border-top: $g-border-width solid $g-border-color;
    margin-bottom: $g-spacer-600;
    padding-top: $g-spacer-base;
    background-color: $g-color-white;

    //padding-top: $g-spacer-600;
  }
  button {
    padding: 0;
    //background-color: transparent;
  }
  .accordion-button {
    font-weight: $g-fw-bold;
    background-color: $g-color-white;
    box-shadow: none;
  }
  .accordion-body {
    color: $g-gray-700;
    padding-left: 0;
  }
}
