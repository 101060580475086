// -----------------------------------------------------------------------------
// Minimal search form
// -----------------------------------------------------------------------------

.minimal-search-form {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: $g-spacer-base;

  &__submit {
    #{$root}:not(.active) & {
      &,
      &:active,
      &:focus {
        background: transparent;
        color: $g-gray-500;
      }
    }

    #{$root}:hover &,
    #{$root}:focus-within & {
      &,
      &:active,
      &:focus {
        background: $g-color-primary;
        color: $g-color-white;
      }
    }
  }

  &__input {
    position: absolute;
    right: calc($g-btn-square-width + $g-spacer-300);
    z-index: calc($zindex-dropdown + 3);
    width: g-px-to-rem(200px);
    transition: opacity $g-transition-property;

    @include media-breakpoint-down(xxl) {
      padding-left: $g-spacer-base;
      padding-right: $g-spacer-base;
    }

    @include media-breakpoint-up(xxl) {
      width: g-px-to-rem(300px);
    }

    #{$root}:not(:hover) & {
      opacity: 0;
    }

    #{$root}:hover,
    #{$root}:focus-within & {
      opacity: 1;
    }

    .was-validated & {
      &:invalid,
      &:valid {
        background-image: none;
        padding-left: $g-spacer-base;
        padding-right: $g-spacer-base;

        &,
        &:focus {
          border-color: $g-gray-500;
        }
      }
    }
  }
}
