// -----------------------------------------------------------------------------
// Info Row
// -----------------------------------------------------------------------------

.info-row {
  display: flex;
  justify-content: space-between;
  max-width: g-px-to-rem(800px);
  border-bottom: $g-border-width solid $g-gray-200;

  padding: g-px-to-rem(16px) 0;
  border-radius: var(--g-border-radius-small);
  font-size: $g-fs-10;
  text-align: center;

  &:last-child {
    border-bottom: 0;
  }
}

.info-row-two-columns {
  @include media-breakpoint-up(lg) {
    width: 47%;
  }
  box-sizing: border-box;
  padding: g-px-to-rem(10px);
  border-bottom: $g-border-width solid $g-gray-200;

  &:nth-last-child(2),
  &:last-child {
    border: 0;
  }
}

.info-row-two-columns-wrapper {
  display: flex;
  max-width: g-px-to-rem(800px);
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  justify-content: space-between;
}
