// -----------------------------------------------------------------------------
// Grid Utility
// -----------------------------------------------------------------------------

.grid-layout {
  display: grid;
  grid-gap: $g-spacer-600;

  &[data-variant='flow-wrap'] {
    --col-width: #{g-px-to-rem(180px)};

    grid-template-columns: repeat(auto-fit, minmax(var(--col-width), 1fr));
    align-items: start;
  }

  &[data-variant='50-50'] {
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &[data-variant='30-30-30'] {
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &[data-variant='25-25-25-25'] {
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
