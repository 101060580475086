/* stylelint-disable property-no-vendor-prefix */
// -----------------------------------------------------------------------------
// Inputs
// -----------------------------------------------------------------------------

.form-control {
  padding: $g-input-btn-mobile-factor * $g-input-btn-padding-y
    $g-input-btn-mobile-factor * $g-input-btn-padding-x * 0.5;

  @include media-breakpoint-up(xl) {
    padding: 10px;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &.is-empty {
    color: $g-gray-700;

    option {
      color: $g-gray-900;
    }
  }

  &.input-lg{
    padding:$g-title-input-padding-y $g-title-input-padding-x;
  }
  
  &::placeholder {
    overflow: hidden;
    color: $g-color-input-placeholder;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Body/Body 1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
