// -----------------------------------------------------------------------------
// Basic transformations helpers
// -----------------------------------------------------------------------------

$rotations: 90, 180, 270;

@each $deg in $rotations {
  .rotate-#{$deg} {
    transform: rotate(#{$deg}deg) !important;
  }
}
