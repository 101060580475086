.library-card {
    display: flex;
    width: 20.25rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.13);

    &--small {
        width: 100%;
        
        .library-card__content {
            height: 4rem;
            padding: 0.5rem;
        }

        .library-card__header-wrapper {
            padding: 0.25rem 0.5rem;
            gap: 0.5rem;
        }

        .library-card__title {
            font-size: 0.75rem;
            line-height: 1rem;
        }

        .library-card__date {
            font-size: 0.625rem;
        }
    }

    &__content {
        height: 6.25rem;
        align-self: stretch;
        border-bottom: 1px solid var(--Color-Grey-100, #F5F4F7);
        padding: 1rem;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    &__header-wrapper {
        display: flex;
        padding: 0.5rem 1rem;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        &--metadata {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
            flex: 1 0 0;
        }

        &--menu {
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__title-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 0.25rem;
        align-self: stretch;
    }

    &__title {
        color: var(--Color-Grey-900, #010028);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.125rem;

        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    &__date {
        font-size: 0.75rem;
        color: var(--Color-Grey-600);
    }
} 