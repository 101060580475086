// -----------------------------------------------------------------------------
// Specific Height and Width utils
// -----------------------------------------------------------------------------

// Height
.h-40vh {
  height: 40vh;
}

.mh-40vh {
  min-height: 40vh;
}

.mh-30px {
  min-height: 30px;
}

.mh-45px {
  min-height: 45px !important;
}

.mh-150px {
  min-height: 150px !important;
}

.mh-170px {
  min-height: 170px !important;
}

.mh-300px {
  min-height: 300px !important;
}

.max-h-400px {
  max-height: 400px !important;
}

// Widths
.w-28px {
  width: 28px;
}

.w-80px {
  width: 80px;
}

.w-150px {
  width: 150px !important;
}

.w-300px {
  width: 300px;
}

.w-450px {
  width: 450px;
}

.w-500px {
  width: 500px;
}

.mw-0 {
  min-width: 0 !important;
}

.mw-200px {
  min-width: 200px !important;
}

.mw-300px {
  min-width: 100% !important;

  @include media-breakpoint-up(md) {
    min-width: 300px !important;
  }
}

.max-w-121px {
  max-width: 121px !important;
}

.max-w-200px {
  max-width: 200px !important;
}

.max-w-300px {
  max-width: 300px !important;
}

.max-w-500px {
  max-width: 500px !important;
}

.max-w-600px {
  max-width: 600px !important;
}

.max-w-800px {
  max-width: 800px !important;
}

.max-w-900px {
  max-width: 900px !important;
}

.h-fit-content {
  height: fit-content;
}

.w-fit-content {
  width: fit-content;
}
