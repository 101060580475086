// -----------------------------------------------------------------------------
// Icon fonts variable - font by ico-moon.io
// -----------------------------------------------------------------------------

$icon-font-family: 'ditto-icon';
$icon-font-path: '../../../public/fonts/ditto-icon-font';
$icon-font-default-size: $g-fs-600;

$icons-list: (
  'event': '\e901',
  'chart': '\e902',
  'chevron': '\e903',
  'close': '\e904',
  'export': '\e905',
  'link': '\e906',
  'live': '\e907',
  'magnifier': '\e908',
  'mail': '\e909',
  'minus': '\e90a',
  'phone': '\e90b',
  'plus': '\e90c',
  'warning': '\e90d',
  'add-user': '\e90e',
  'clear': '\e910',
  'home': '\e915',
  'delete': '\e914',
  'at-sign': '\e913',
  'edit': '\e912',
  'assign': '\e911',
  'chevron-right': '\e916',
  'chevron-left': '\e917',
  'back': '\e92e',
  'success': '\e918',
  'note': '\e91a',
  'users': '\e91c',
  'more-info': '\e91d',
  'select-toggle': '\e91e',
  'change': '\e920',
  'info': '\e921',
  'user': '\e922',
  'calendar-arrow': '\e923',
  'download': '\e925',
  'map': '\e926',
  'upload': '\e927',
  'upload-history': '\e928',
  'bell': '\e929',
  'pin': '\e92a',
  'refresh': '\e92b',
  'breadcrumb': '\e92d',
  'next': '\e919',
  'shield': '\e930',
  'leaf': '\e92f',
  'document': '\e931',
  'hamburger': '\e932',
  'gear': '\e933',
  'arrow-up': '\e934',
  'arrow-down-circle': '\e935',
  'arrow-up-circle': '\e936',
  'filters': '\e937',
  'user-star': '\e939',
  'three-dots': '\e93d',
  'shield-fill': '\e93c',
  'compass': '\e93b',
  'switch': '\e93e',
  'movie': '\e946',
  'reading': '\e945',
  'headphone': '\e944',
  'check-in': '\e947',
  'question': '\e948',
  'check-circle': '\e94a',
  'minus-circle': '\e949',
  'report': '\e94b',
  'star': '\e94c',
  'star-outline': '\e94d',
  'checkmark': 'checkmark',
);
