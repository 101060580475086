/* stylelint-disable  selector-class-pattern */
// -----------------------------------------------------------------------------
// Negative margins
// -----------------------------------------------------------------------------

.mx-negative-gutter {
  margin-left: calc(-1 * var(--g-grid-gutter, 1.5rem));
  margin-right: calc(-1 * var(--g-grid-gutter, 1.5rem));
}

.mt-negative-gutter {
  margin-top: calc(-1 * var(--g-grid-gutter, 1.5rem));
}

.first\:mt-negative-gutter {
  &:first-child {
    margin-top: calc(-1 * var(--g-grid-gutter, 1.5rem));
  }
}

.mt-negative-base {
  margin-top: -1 * $g-spacer-base;
}

.mt-lg-labelHeight {
  @include media-breakpoint-up(lg) {
    margin-top: calc(0.5rem + 1.6 * var(--g-fs-300));
  }
}

.me-50 {
  margin-right: 0.125rem !important;
}
