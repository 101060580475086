// -----------------------------------------------------------------------------
// Bootstrap modal
// -----------------------------------------------------------------------------
.modal {
  --modal-y-padding: calc(1.375 * var(--g-grid-gutter));
  --modal-x-padding: calc(2 * var(--g-grid-gutter));

  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }

  .modal-content {
    @include media-breakpoint-down(sm) {
      border-radius: 0 0 var(--g-border-radius-large)
        var(--g-border-radius-large);
    }
  }

  .modal-header {
    padding: calc(2.25 * var(--g-grid-gutter)) var(--modal-x-padding)
      var(--modal-y-padding) var(--modal-x-padding);

    .btn-close {
      padding: 0;
      margin: 0;
    }
  }

  .modal-body {
    --g-flow-space: 1rem;

    @include media-breakpoint-down(xl) {
      word-break: break-word;
    }

    padding: 0 var(--modal-x-padding);
  }

  .modal-title {
    @include g-apply-text-style('h2');
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: var(--g-grid-gutter);
    padding: var(--modal-y-padding) var(--modal-x-padding);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  // -----------------------------------------------------------------------------
  // Extensions
  // -----------------------------------------------------------------------------

  // Secondary modal above first
  & + .modal-backdrop {
    z-index: $g-zindex-modal-secondary;

    & + .modal {
      z-index: $g-zindex-modal-secondary;
    }
  }

  &.xxl {
    .modal-dialog {
      @include media-breakpoint-up(xxl) {
        min-width: $modal-xxl;
      }
    }
  }
}
