// -----------------------------------------------------------------------------
// Line clamp utilities
// -----------------------------------------------------------------------------

.line-clamp-1 {
  @include g-line-clamp(3);
}

.line-clamp-2 {
  @include g-line-clamp(3);
}

.line-clamp-3 {
  @include g-line-clamp(3);
}
