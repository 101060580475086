// -----------------------------------------------------------------------------
// Bar component
// -----------------------------------------------------------------------------

.bar {
  display: flex;
  align-items: center;
  gap: $g-spacer-200;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-breakpoint-up(xl) {
    gap: var(--g-grid-gutter);
  }

  &,
  > .h1,
  > .btn:not(.icon-circle):not(.btn-label-icon),
  > .search-input,
  > form {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}
