// -----------------------------------------------------------------------------
// Custom icon sizes
// -----------------------------------------------------------------------------

.icon-size-100 {
  font-size: 0.5rem; // 8px
}

.icon-size-200 {
  font-size: 0.625rem; // 10px
}

.icon-size-300 {
  font-size: 0.75rem; // 12px
}

.icon-size-base {
  font-size: 0.9375rem; // 15px
}

.icon-size-500 {
  font-size: 1rem; // 16px
}

.icon-size-600 {
  font-size: 1.125rem; // 18px
}

.icon-size-700 {
  font-size: 1.25rem; // 20px
}

.icon-size-750 {
  font-size: 1.5rem; // 24px
}

.icon-size-800 {
  font-size: 2rem; // 32px
}

.icon-size-900 {
  font-size: 3rem; // 48px
}
