@import "/src/App";
.container {
    align-self: stretch;
    border-radius: 5px;
    background: $g-gray-150;
    max-width: 284px;
    padding: $g-spacer-base $g-spacer-200;
}

.header {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.title {
    color: #000;
    text-transform: uppercase;
    letter-spacing: 2.24px;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.4;
    margin: 0;
}

.protocolSection {
    margin-top: 16px;
}

.protocolHeader {
    margin-bottom: 8px;
}

.protocolTitle {
    color: var(--Color-Grey-900, #010028);
    font-size: 14px;
    font-weight: 700;
}

.protocolDescription {
    color: var(--Color-Grey-700, #797980);
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
}

.protocolSelector {
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.protocolLabel {
    color: var(--Color-Grey-900, #010028);
    font-size: 16px;
    font-weight: 400;
}

.selectorIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.optionContainer {
    margin-top: 16px;
}

.optionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.optionTitle {
    color: var(--Color-Grey-900, #010028);
    font-size: 14px;
    font-weight: 700;
}

.toggle {
    border-radius: 50px;
    background-color: rgba(69, 168, 224, 1);
    display: flex;
    width: 40px;
    height: 20px;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2px;
    border: none;
    cursor: pointer;
}

.toggleOff {
    background-color: $g-gray-350;
    justify-content: flex-start;
}

.toggleOffDisabled {
    background-color: $g-gray-250;
    justify-content: flex-start;
}

.toggleOnDisabled {
    background-color: $g-gray-250;
    justify-content: flex-end;
}

.toggleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    object-fit: contain;
    border-radius: 16px;
    background: white;
}

.optionDescription {
    color: var(--Color-Grey-700, #797980);
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
