// stylelint-disable scss/no-global-function-names

// -----------------------------------------------------------------------------
// Global colors
// -----------------------------------------------------------------------------

$g-color-primary: var(--g-color-primary, #010028);
$g-color-primary1: var(--g-color-primary1, #292850);
$g-color-primary2: var(--g-color-primary2, #515078);
// todo check color primary-*
$g-color-primary-100: #EDF8FE;
$g-color-primary-200: #b2d4dd;
$g-color-primary-300: #1a7e98;

$g-color-primary-dark-100: hsl(
  var(--g-color-primary-hue) var(--g-color-primary-saturation)
    calc(var(--g-color-primary-lightness) - 10%)
);

$g-color-black: #000;
$g-color-white: #fff;

$g-color-success: #08b69c;
$g-color-success-100: transparentize($g-color-success, 0.91);
$g-color-success-150: transparentize($g-color-success, 0.76);
$g-color-success-200: transparentize($g-color-success, 0.6);

$g-color-warning: #e47e07;
$g-color-warning-100: transparentize($g-color-warning, 0.94);
$g-color-warning-150: transparentize($g-color-warning, 0.78);
$g-color-warning-200: transparentize($g-color-warning, 0.6);
$g-color-warning-500: transparentize($g-color-warning, 0.3);
$g-color-warning-dark: #a05600;

$g-color-danger: #de2f4f;
$g-color-danger-100: transparentize($g-color-danger, 0.91);
$g-color-danger-200: transparentize($g-color-danger, 0.76);
$g-color-danger-dark: #a1384c;

$g-color-info: #00708d;
$g-color-info-100: transparentize($g-color-info, 0.92);
$g-color-info-200: transparentize($g-color-info, 0.6);

$g-gray-100: #f9f9f9;
$g-gray-150: #F5F4F7;
$g-gray-200: #d9d9d9;
$g-gray-250: #E1E1E1;
$g-gray-300: #ccc;
$g-gray-350: #BABAC2;
$g-gray-400: #9D9DA3;
$g-gray-450: #797980;
$g-gray-500: #6e7272;
$g-gray-700: #5A545C;
$g-gray-900: #333;
$g-gray-1000: #202020;
$g-gray-bg: #F9F9FA;

$g-color-shadow: #dedcdf;

$g-color-input-placeholder: #9197A3;

$g-colors-list: (
  'gray-100': $g-gray-100,
  'gray-200': $g-gray-200,
  'gray-300': $g-gray-300,
  'gray-500': $g-gray-500,
  'gray-700': $g-gray-700,
  'gray-900': $g-gray-900,
  'gray-1000': $g-gray-1000,
  'gray-bg': $g-gray-bg,
  'primary-100': $g-color-primary-100,
  'primary-200': $g-color-primary-200,
  'primary-300': $g-color-primary-300,
  'success-100': $g-color-success-100,
  'success-200': $g-color-success-200,
  'danger-100': $g-color-danger-100,
  'danger-200': $g-color-danger-200,
  'danger-dark': $g-color-danger-dark,
  'warning-100': $g-color-warning-100,
  'warning-200': $g-color-warning-200,
  'warning-dark': $g-color-warning-dark,
  'info-100': $g-color-info-100,
  'info-200': $g-color-info-200,
);
