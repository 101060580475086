// Body
$g-body-bg: $g-gray-100;
$g-body-color: $g-gray-900;
$g-font-size-base: 1rem;
$g-font-weight-normal: 400;
$g-font-weight-semibold: 500;
$g-font-weight-bold: 700;

$container-padding-x: 0;
$container-padding-y: 0;

// Z-index
$g-zindex-fixed: 1030;
$g-zindex-modal-backdrop: 1050;
$g-zindex-modal: 1055;
$g-zindex-modal-secondary: 1056;

$g-zindex-offcanvas-more: 1210;
$g-zindex-app-bottom-navigation: 1220;
$g-zindex-offcanvas-backdrop: 1240;
$g-zindex-offcanvas: 1250;

// Layout
$g-header-height: g-px-to-rem(56px);
$g-header-height-mobile: g-px-to-rem(56px);
$g-sidebar-width: g-px-to-rem(90px);
$g-bottom-navigation-height: 4.25rem;

$font-family: 'Uncut-Sans-VF';
$icon-font-path: '../../../public/fonts/uncut-sans';
@font-face {
  font-family: 'Uncut Sans VF';
  src: url('#{$icon-font-path}/#{$font-family}-Regular.woff2') format('woff2');
  src: url('#{$icon-font-path}/#{$font-family}-Regular.woff') format('woff');
  src: url('#{$icon-font-path}/#{$font-family}.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

$g-font-family-sans-serif: $font-family, helvetica, arial, sans-serif;

// Buttons
/*$g-input-btn-padding-x: 2rem;
$g-input-btn-padding-y: 0.8rem;*/
$g-input-btn-padding-x: 10px;
$g-input-btn-padding-y: 4px;
$g-input-btn-mobile-factor: 0.65;
$g-btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
$g-btn-square-width: 52px;

// Custom Scrollbar
$g-scrollbar-size: 4px;
$g-scrollbar-radius: 2px;
$g-scrollbar-color: $g-gray-900;
$g-scrollbar-bg: $g-gray-200;

// Borders
$g-border-width: 1px;
$g-border-color: $g-gray-200;
$g-border: $g-border-width solid $g-gray-200;

// Shadow
$g-box-shadow: 0 8px 12px rgba($g-color-shadow, 0.35);
$g-box-shadow-100: 0 4px 16px rgba($g-color-shadow, 0.35);
$g-box-shadow-200: 0 2px 0 $g-gray-200;
$g-box-shadow-300: 0 4px 16px rgba($g-color-shadow, 0.64);
$g-box-shadow-400: 0 -4px 32px rgba($g-color-shadow, 0.52);

// Dropdown
$g-dropdown-toggle-size: 1rem;
$g-dropdown-user-menu-width: 22rem;

// Transition
$g-transition-base: all 0.2s ease-in-out;
$g-transition-property: 0.15s ease-in-out;

// Sidebar navigation
$nav-icon-wrapper-size: g-px-to-rem(32px);

// Tables
$g-table-cell-padding-y: 0.75rem;
$g-table-cell-padding-x: 1rem;
$g-table-cell-width-default: g-px-to-rem(240px);
$g-table-cell-width-wide: g-px-to-rem(550px);

// Spinner
$spinner-min-height: 100px;

// Forms
$g-input-border-color: $g-gray-300;
$g-form-validation-icon-size: 1.1rem;
$g-form-checkbox-width: 20px;

// Grid
$g-row-gutter: 2rem;

// Icons
$g-icon-wrapper-size-base: g-px-to-rem(32px);
$g-icon-wrapper-size-500: g-px-to-rem(48px);
$g-icon-wrapper-size-600: g-px-to-rem(52px);
$g-icon-wrapper-size-700: g-px-to-rem(64px);

// Fade In / Out animation
$g-fade-anim-duration: 0.25s;

$g-placeholder-opacity-max: 0.5;
$g-placeholder-opacity-min: 0.2;

// Toast
$g-toast-zindex: 2000;
$g-toast-width: g-px-to-rem(600px);

// Badge

$g-badge-padding-y: g-px-to-rem(4px);
$g-badge-padding-x: g-px-to-rem(5px);

// Landing page
$landing-page-logo-img-height: g-px-to-rem(40px);

// Modal and Offcanvas
$g-backdrop-opacity: 0.32;

// Custom form input
$g-title-input-padding-x: 10px;
$g-title-input-padding-y: 10px;
