.prose {
  p,
  ul {
    --g-flow-space: 1em;
  }

  h1 {
    --g-flow-space: 3rem;
  }

  h2 {
    --g-flow-space: 2rem;
  }

  h3 {
    --g-flow-space: 1rem;
  }

  .table {
    td,
    th,
    p,
    li {
      font-size: $g-fs-200;
    }
  }

  // <table> styles - copy from Bootstrap
  table {
    width: 100%;
    border-color: $g-border-color;

    > :not(caption) > * > * {
      padding: $g-table-cell-padding-y $g-table-cell-padding-x;
      border-bottom-width: $table-border-width;
    }
  }
}
