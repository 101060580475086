/* stylelint-disable no-descending-specificity */
// -----------------------------------------------------------------------------
// Form validation
// -----------------------------------------------------------------------------

.invalid-feedback {
  margin-top: g-px-to-rem(12px);
  margin-bottom: 5px;
  display: block;
  visibility: hidden;
}

.is-invalid ~ .invalid-feedback {
  margin-bottom: 0;
  visibility: visible;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-size: $g-form-validation-icon-size;
  background-image: none;
  padding-right: g-px-to-rem(5px);

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-size: $g-form-validation-icon-size;
  border-color: $g-input-border-color;

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.was-validated {
  .form-check-input {
    &:valid,
    &:valid:checked {
      background-color: $g-color-white;
      border-color: $g-input-border-color;

      & ~ .form-check-label {
        color: inherit;
      }
    }

    &:focus,
    &:focus:valid {
      box-shadow: none;
    }
  }

  .is-invalid {
    .form-check-input {
      border-color: $g-color-danger;
    }

    &.form-control {
      border-color: $g-color-danger;
    }
  }

  .form-control {
    &[type='time'] {
      &.is-invalid,
      &:invalid,
      &:valid {
        padding-right: $g-spacer-base;
        background-image: none;
      }

      &:valid {
        border-color: $g-input-border-color;
      }

      &.is-invalid,
      &:invalid {
        border-color: $g-color-danger;
      }
    }
  }
}

.has-validation {
  .invalid-feedback {
    display: block;
  }
}

.form-label.required {
  &:before {
    content: '*';
    margin-right: 3px;
    color: $g-color-danger;
  }
}

.form-label {
  color: var(--Color-Grey-900, #212529);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  margin-bottom: 0;
}

.form-control {
  > input {
    border-radius: 0.5rem;
    border: 1px solid #3C3C46;
    background: #FFF;
    display: flex;
    padding: 0.625rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
  }
}
