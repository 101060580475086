.container {
  border-radius: 16px;
  display: flex;
  max-width: 432px;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  border-radius: 467px;
  display: flex;
  background: linear-gradient(45deg, rgba(76, 185, 246, 0.20) 0%, rgba(23, 238, 212, 0.20) 100%);
  min-height: 112px;
  width: 112px;
  align-items: center;
  justify-content: center;
  padding: 27px 19px;
}

.headerIcon {
  aspect-ratio: 0.67;
  object-fit: contain;
  width: 39px;
}

.title {
  color: var(--Color-Grey-800, #5a545c);
  margin-top: 8px;
  font: 700 16px/1 Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
}

.controls {
  display: flex;
  margin-top: 32px;
  width: 300px;
  max-width: 100%;
  flex-direction: column;
  gap: 24px;
}

.inputGroup {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.inputLabel {
  color: var(--Color-Grey-800, #5a545c);
  font: 400 16px Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
}

.deviceButton {
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--Color-Grey-400, #d0d0d6);
  background: var(--Color-Grey-000, #fff);
  box-shadow: 0px 2px 10px 0px rgba(1, 0, 40, 0.05);
  display: flex;
  gap: 8px;
  width: 180px;
  padding: 10px 16px;
}

.deviceLabel {
  color: var(--Color-Grey-900, #010028);
  font: 500 16px Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
  letter-spacing: 0.02px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.deviceIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.levelContainer {
  display: flex;
  align-items: center;
  gap: 3px;
  width: 180px;
  padding: 0 16px;
}

.levelBar {
  border-radius: 4px;
  background: var(--Color-Grey-300, #e2e2f0);
  height: 31px;
  flex: 1;
}

.levelBarActive {
  background: var(--Color-Grey-700, #797980);
}

.recordButton {
  width: 300px;
  max-width: 100%;
  margin-top: 32px;
  padding: 16px 24px;
  border-radius: 10px;
  background: var(--Color-Button, linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%), #010028);
  box-shadow: 0px 2px 10px 0px rgba(1, 0, 40, 0.05);
  color: #fff;
  text-align: center;
  font: 500 16px Uncut Sans VF, -apple-system, Roboto, Helvetica, sans-serif;
  letter-spacing: 0.02px;
  border: none;
  cursor: pointer;
}

.submitBtn {
  margin-top: 32px;
  background: var(--Color-Button, #0D164F);
  padding: 16px 24px;
 }
