/* stylelint-disable no-descending-specificity */
// -----------------------------------------------------------------------------
// BS Form check / Switch
// -----------------------------------------------------------------------------

.form-check {
  line-height: 1;
}

.form-check-input {
  font-size: $g-fs-300;
  width: $g-form-checkbox-width;
  height: $g-form-checkbox-width;

  &:focus {
    box-shadow: none;
  }

  &:checked {
    &[type='checkbox'] {
      background-size: 12px;
      border-color: $g-input-border-color;
    }
  }

  &:valid {
    &,
    &:checked {
      border-color: $g-input-border-color;
      background-color: $g-color-white;
      color: inherit;
    }
  }

  & ~ .form-check-label {
    user-select: none;
  }

  &:valid ~ .form-check-label,
  &.is-valid ~ .form-check-label {
    color: inherit;
  }

  &[type='radio'] {
    width: 1rem;
    height: 1rem;
  }

  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    color: $g-gray-700;
  }
}

.form-switch {
  margin-bottom: 0;
  color: $g-gray-900;

  .form-check-input {
    height: 20px;

    &:checked {
      background-size: 16px;

      &:valid {
        border-color: $g-gray-900;
        background-color: $g-gray-900;
      }

      &:disabled {
        border-color: $g-gray-500;
        background-color: $g-gray-500;
      }
    }

    .form-check-label {
      @include g-apply-text-style('regular-200');
    }

    &:not(:disabled) {
      &:hover {
        cursor: pointer;
      }

      & + .form-check-label {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
