// -----------------------------------------------------------------------------
// Attachment Counter
// -----------------------------------------------------------------------------

.attachment-counter {
  display: flex;
  height: 1.125rem;
  padding: 0.125rem 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.25rem;
  background: var(--Color-Grey-100, #F5F4F7);
  color: $g-gray-450;

  &__count {
    color: var(--Color-Grey-700, #797980);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.75rem;
    letter-spacing: 0.07125rem;
    text-transform: uppercase;
  }
}
