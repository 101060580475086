// -----------------------------------------------------------------------------
// Choice card
// -----------------------------------------------------------------------------

.choice-card {
  border-radius: var(--g-border-radius);
  border: 2px solid $g-gray-200;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .circle {
    background-color: $g-color-primary-100;
    height: g-px-to-rem(56px);
    width: g-px-to-rem(56px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g-color-info;
  }

  &.active {
    background-color: $g-color-primary-100;
    border: 2px solid $g-color-info;

    .circle {
      background-color: $g-color-primary-200;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  @include media-breakpoint-down(sm) {
    padding: $g-spacer-100;
  }
}
