// -----------------------------------------------------------------------------
// Content Library
// -----------------------------------------------------------------------------

.content-library {
    display: flex;
    height: 52.75rem;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 0 0;

    &__nav-tabs {
        display: flex;
        height: 3.125rem;
        padding: 0rem 1.5rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        border-bottom: 1px solid #D9D9D9;

        > .nav-item {
            display: flex;
            padding: 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;

            > .nav-link {
                text-align: center;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.125rem;
            }

            &:has(.nav-link.active) {
                border-bottom: 2px solid #000;
            }
        }
    }

    &__content-wrapper {
        display: flex;
        height: 49.6875rem;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        flex-shrink: 0;
        align-self: stretch;

        > .tab-pane {
            display: flex;
            align-self: stretch;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1.5rem;

        &-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 0 1.5rem;
            margin-bottom: 1.5rem;

            
        
            .search {
                display: flex;
                width: 15rem;
                padding: 0.25rem 0.625rem;
                align-items: center;
                gap: 0.5rem;
            }

            .add-button {
                display: flex;
                padding: 0.375rem 1rem;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;

                .btn-secondary {
                    border-radius: 0.625rem;
                    border: 1px solid var(--Color-Grey-400, #D0D0D6);
                    background: var(--Color-Grey-000, #FFF);
                    box-shadow: 0px 2px 10px 0px rgba(1, 0, 40, 0.05);
                  }
            }
        }

        &-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.75rem;
            padding: 0 1.5rem;

            &__wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.75rem;
                width: 100%;
            }

            &__title {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1rem;
                letter-spacing: 0.14rem;
                text-transform: uppercase;
                opacity: 0.4;
            }

            &__content {
                display: flex;
                max-width: 62.3125rem;
                align-items: flex-start;
                align-content: flex-start;
                gap: 0.75rem;
                align-self: stretch;
                flex-wrap: wrap;
            }
        }
    }
}

.content-grid,
.template-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
}

.template-card {
    display: flex;
    width: 20.25rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.13);

    &__content {
        height: 6.25rem;
        align-self: stretch;
        border-bottom: 1px solid var(--Color-Grey-100, #F5F4F7);
        padding: 1rem;

        &-wrapper{
            overflow-y: hidden;
            height: calc(100% + 1rem);
        }

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    &__header-wrapper {
        display: flex;
        padding: 0.5rem 1rem;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        &--metadata {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
            flex: 1 0 0;
        }

        &--menu {
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__title-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 0.25rem;
        align-self: stretch;
    }

    &__title {
        color: var(--Color-Grey-900, #010028);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.125rem;

        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    &__tag {
        display: flex;
        height: 1.125rem;
        padding: 0.125rem 0.25rem;
        justify-content: center;
        align-items: center;
        gap: 0.125rem;
        border-radius: 0.25rem;
        background: var(--Color-Grey-100, #F5F4F7);
    }

    &__date {
        align-self: stretch;
        color: var(--Color-Grey-700, #797980);
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.02188rem;
        text-transform: capitalize;
    }
}

