// -----------------------------------------------------------------------------
// Base css
// -----------------------------------------------------------------------------

a {
  color: var(--g-color-primary, #0B7BB7);

  &:hover {
    color: $g-color-primary-dark-100;
  }
}

ul {
  &:empty {
    display: none;
  }
}

ul {
  list-style: none;
}

ul li:before {
  content: '•';
  padding-right: 7px;
}

ul li.success:before {
  content: '\2714\0020';
  //content: '✓';
  color: $g-color-success;
}

ul li.success {
  color: $g-color-success;
}
