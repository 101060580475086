/* stylelint-disable no-duplicate-selectors, no-descending-specificity */
// -----------------------------------------------------------------------------
// Dropdown - Bootstrap defaults
// -----------------------------------------------------------------------------

.dropdown {
  .dropdown-toggle {
    position: relative;
    width: 100%;
    border-color: $g-input-border-color;
    justify-content: flex-start;
    max-width: 100%;
    background-color: $g-color-white;

    &:disabled {
      background-color: $g-gray-100;
    }

    &::after {
      @include g-add-icon-font('select-toggle');

      position: absolute;
      top: 50%;
      right: $g-spacer-base;
      transform: translateY(-50%);
      transform-origin: center;
      font-size: $g-dropdown-toggle-size;
      border: 0;
      color: $g-gray-700;
    }
  }

  &.show {
    .dropdown-toggle {
      z-index: calc($zindex-dropdown + 1);
    }
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: $g-box-shadow;
    width: 100%;
    margin-top: -$g-spacer-300;
    padding: $g-spacer-0;

    @include media-breakpoint-up(xl) {
    }

    .dropdown-filter-list {
      @include g-custom-scrollbar(
        $g-scrollbar-size,
        $g-scrollbar-radius,
        $g-scrollbar-color,
        $g-scrollbar-bg
      );

      max-height: 250px;
      overflow-y: auto;
    }
  }

  &.is-invalid {
    .dropdown-toggle {
      border-color: $g-color-danger;
    }
  }

  .dropdown-item {
    &:hover {
      background-color: $g-gray-bg;
    }

    & > .selected {
      font-weight: 700;
      color: $g-gray-1000;
    }

    .labeled-form-check label,
    .filterable-select {
      padding-top: $g-spacer-200;
      padding-bottom: $g-spacer-200;
      cursor: pointer;
    }
  }

  [data-variant='dropdown-expandable'] {
    margin-right: $g-spacer-100;
    padding-block: $g-spacer-100;

    &[data-is-expanded='true'] {
      &:not(:last-child) {
        border-bottom: $g-border-width solid $g-border-color;
        margin-bottom: $g-spacer-200;
      }
    }

    [data-variant='dropdown-expandable'] {
      margin-right: 0;
      margin-left: $g-spacer-500;
    }
  }
}

// -----------------------------------------------------------------------------
// Dropdown - extensions
// -----------------------------------------------------------------------------
.dropdown {
  &.dropdown-user-menu {
    width: $g-dropdown-user-menu-width;
    height: 100%;
    /*border-left: $g-border-width solid $g-border-color;*/

    .dropdown-toggle {
      color: $g-body-color;
      padding-right: 2 * $g-spacer-600;
      border-color: transparent;

      &::after {
        @include g-add-icon-font('chevron');

        right: $g-spacer-600;
        font-size: 0.5rem;
      }
    }

    .dropdown-menu {
      margin-top: 0;
      inset: -1px 0 auto 0 !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-width: 0;

      .dropdown-item {
        font-weight: $g-font-weight-semibold;

        color: var(--g-color-link);
        padding: $g-spacer-300 $g-spacer-700;
      }
    }

    &.show {
      .dropdown-toggle {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &.dropdown-search {
    .search-input {
      width: 100%;
      margin: $g-spacer-base 0;

      .form-control {
        background-image: none;
        border-color: $g-input-border-color;

        &:focus {
          border-color: $g-gray-500;
        }
      }
    }
  }

  &.dropdown-user-role {
    .dropdown-item {
      &:hover {
        text-decoration: none;
      }

      .labeled-form-check {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.dropdown-language {
    height: 100%;

    @include media-breakpoint-up(xl) {
      border-left: $g-border-width solid $g-border-color;
    }

    .dropdown-toggle {
      height: 100%;
      border: 0;
      margin: auto;
      padding-right: $g-spacer-800;
    }

    .dropdown-menu {
      margin-top: 0;
      padding-top: $g-spacer-200;
      inset: -1px 0 auto 0 !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-width: 0;

      .dropdown-item {
        font-weight: $g-font-weight-semibold;
        color: $g-color-primary;
        padding-top: $g-spacer-200;
        padding-bottom: $g-spacer-200;
      }
    }
  }
}

a.dropdown-item {
  &,
  .dropdown & {
    &[data-type='switch-link'] {
      display: flex;
      align-items: center;
      border-bottom: $g-border;
      text-decoration: none;
      font-size: $g-fs-14;
      font-weight: $g-fw-medium;

      @include media-breakpoint-down(xl) {
        padding-block: $g-spacer-300;
      }

      .icon-switch {
        text-decoration: none;
      }

      .dropdown-item-label {
        text-decoration: underline;
      }

      &:hover {
        .dropdown-item-label {
          text-decoration: none;
        }
      }
    }
  }
}

select {
  &.form-control {
    background: url('../../../public/media/select-toggle.svg') calc(100% - 1rem)
      50% no-repeat;
    background-size: $g-dropdown-toggle-size;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}
