// -----------------------------------------------------------------------------
// Define Bootstrap variables
// -----------------------------------------------------------------------------

// Colors
$primary: #0B7BB7;
$secondary: $g-gray-900;
$white: $g-color-white;
$light: $g-gray-300;

$success: $g-color-success;
$warning: $g-color-warning;
$danger: $g-color-danger;

$gray-100: $g-gray-100;
$gray-200: $g-gray-200;

// Body Colors
$body-bg: $g-color-white;
$body-color: $g-body-color;

// Typography
$font-family-sans-serif: $g-font-family-sans-serif;
$font-size-base: $g-font-size-base;
$headings-font-weight: $g-fw-bold;
$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;

// Grid
$grid-gutter-width: $g-row-gutter;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1550px,
);

// Spacers overwrites
$spacers: (
  0: $g-spacer-0,
  100: (
    $g-spacer-100,
  ),
  150: (
    $g-spacer-150,
  ),
  200: (
    $g-spacer-200,
  ),
  300: (
    $g-spacer-300,
  ),
  400: (
    $g-spacer-base,
  ),
  base: $g-spacer-base,
  500: (
    $g-spacer-500,
  ),
  600: (
    $g-spacer-600,
  ),
  650: (
    $g-spacer-650,
  ),
  700: (
    $g-spacer-700,
  ),
  715: (
    $g-spacer-715,
  ),
  725: (
    $g-spacer-725,
  ),
  750: (
    $g-spacer-750,
  ),
  800: (
    $g-spacer-800,
  ),
  900: (
    $g-spacer-900,
  ),
  925: (
    $g-spacer-925,
  ),
  gutter: var(--g-grid-gutter),
);

// Font sizes map overwrite
$font-sizes: (
  8: $g-fs-8,
  10: $g-fs-10,
  12: $g-fs-12,
  14: $g-fs-14,
  16: $g-fs-16,
  18: $g-fs-18,
  20: $g-fs-20,
  200: $g-fs-200,
  300: $g-fs-300,
  base: $g-fs-base,
  500: $g-fs-500,
  600: $g-fs-600,
  700: $g-fs-700,
  800: $g-fs-800,
  900: $g-fs-900,
);

// Borders
$border-width: $g-border-width;
$border-radius: #{var(--g-border-radius)};
$border-color: $g-border-color;

// Shadow
$box-shadow: $g-box-shadow;

// Inputs and Buttons shared variables
$input-btn-padding-y: $g-input-btn-padding-y;
$input-btn-padding-x: $g-input-btn-padding-x;

// Button
$btn-link-hover-color: $g-color-white;

// Dropdown
$dropdown-link-color: $g-gray-700;
$dropdown-link-hover-color: $g-gray-900;
$dropdown-link-hover-bg: transparent;
$dropdown-link-active-color: $g-gray-900;
$dropdown-link-active-bg: transparent;
$dropdown-border-width: $g-border-width;
$dropdown-item-padding-y: 0;
$dropdown-item-padding-x: 0;
$dropdown-font-size: $g-fs-14;
$dropdown-border-color: $g-gray-300;

// Transition
$transition-base: $g-transition-base;

// Links
$link-decoration: none;
$link-hover-decoration: underline;

// Text
$text-muted: $g-gray-500;

// Navigation
$nav-link-padding-y: 0;
$nav-link-padding-x: $g-spacer-base;

// Table
$table-cell-padding-y: $g-table-cell-padding-y;
$table-cell-padding-x: $g-table-cell-padding-x;
$table-bg: $g-color-white;
$table-striped-bg: $g-gray-100;

// Spinner
$spinner-width: g-px-to-rem(22px);
$spinner-height: $spinner-width;
$spinner-border-width: g-px-to-rem(2px);

// Modals
$zindex-modal: $g-zindex-modal;
$zindex-modal-backdrop: $g-zindex-modal-backdrop;
$modal-content-border-width: 0;
$modal-inner-padding: $g-spacer-800;
$modal-header-padding-y: 0;
$modal-md: 90vw;
$modal-xxl: 1400px;
$modal-footer-margin-between: 0;
$modal-backdrop-opacity: $g-backdrop-opacity;

// Offcanvas
$zindex-offcanvas: $g-zindex-offcanvas;
$zindex-offcanvas-backdrop: $g-zindex-offcanvas-backdrop;
$offcanvas-padding-y: $g-spacer-300;
$offcanvas-padding-x: $g-spacer-600;
$offcanvas-backdrop-opacity: $g-backdrop-opacity;

// Forms
$input-bg: $g-color-white;
$input-border-color: $g-input-border-color;
$input-focus-border-color: $g-gray-500;
$input-btn-focus-color: transparent;
$input-btn-focus-box-shadow: none;
$input-focus-width: 0;
$input-placeholder-color: $g-gray-700;
$input-plaintext-color: $g-body-color;
$input-font-weight: $g-fw-medium;
$form-label-color: $g-gray-700;
$form-label-font-size: var(--g-fs-300);

$form-check-input-width: 1.375em;
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 12 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1,0,0,1,0.499979,-0.500053)'%3E%3Cpath d='M3.43,9.369L9.908,1.04C10.162,0.713 10.634,0.654 10.96,0.908C11.287,1.162 11.346,1.634 11.092,1.96L4.092,10.96C3.96,11.13 3.761,11.235 3.547,11.249C3.332,11.262 3.122,11.182 2.97,11.03L-0.03,8.03C-0.323,7.738 -0.323,7.262 -0.03,6.97C0.262,6.677 0.738,6.677 1.03,6.97L3.43,9.369Z' fill='#{$g-gray-900}' /%3E%3C/g%3E%3C/svg%3E%0A");

$form-check-min-height: 1.44rem;
$form-check-input-checked-color: $g-gray-900;
$form-check-input-checked-bg-color: $g-color-white;
$form-check-label-disabled-opacity: 0.5;
$form-check-input-disabled-opacity: 0.5;

$form-check-input-indeterminate-bg-color: $g-color-white;
$form-check-input-indeterminate-border-color: $g-input-border-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='#{$g-gray-900}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10h8'/%3E%3C/svg%3E");

// Form switch
$form-switch-width: 2.25em;
$form-switch-color: $g-gray-900;
$form-switch-focus-color: $g-gray-900;
$form-switch-checked-color: $g-color-white;

// List
$list-group-color: $g-gray-700;
$list-group-bg: $g-gray-100;
$list-group-border-width: 0;
$list-group-hover-bg: $g-gray-900;

$list-group-active-color: $g-gray-200;
$list-group-active-bg: $g-gray-900;

$list-group-item-padding-y: $g-spacer-base;
$list-group-item-padding-x: $g-spacer-600;

// Tooltip

$tooltip-color: $g-gray-700;
$tooltip-bg: $g-gray-100;
$tooltip-opacity: 1;
$tooltip-padding-y: $g-spacer-300;
$tooltip-padding-x: $g-spacer-base;
$tooltip-arrow-color: $g-gray-100;

// hr

$hr-opacity: 1;

// Accordion
$accordion-button-bg: $g-color-white;
$accordion-button-active-bg: $g-color-primary-300;
$accordion-button-active-color: $g-color-black;

// Progress Bar
$progress-height: 6px;
$progress-bg: $g-gray-100;
$progress-border-radius: 3px;
$progress-bar-color: $g-gray-1000;
$progress-bar-bg: var(--g-color-primary);

// Carousel

$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$carousel-indicator-spacer: $g-spacer-200;
$carousel-indicator-opacity: 1;
$carousel-indicator-active-bg: $g-color-white;
$carousel-indicator-active-opacity: 1;
