// Spacers
$g-spacer-base: 1rem;
$g-spacer-0: 0; // 0px
$g-spacer-50: $g-spacer-base * 0.125; // 2px
$g-spacer-100: $g-spacer-base * 0.25; // 4px
$g-spacer-150: $g-spacer-base * 0.375; // 6px
$g-spacer-200: $g-spacer-base * 0.5; // 8px
$g-spacer-300: $g-spacer-base * 0.75; // 12px
$g-spacer-500: $g-spacer-base * 1.25; // 20px
$g-spacer-600: $g-spacer-base * 1.5; // 24px
$g-spacer-650: $g-spacer-base * 1.75; // 28px
$g-spacer-700: $g-spacer-base * 2; // 32px
$g-spacer-715: $g-spacer-base * 2.25; // 36px
$g-spacer-725: $g-spacer-base * 2.5; // 40px
$g-spacer-750: $g-spacer-base * 2.75; // 44px
$g-spacer-800: $g-spacer-base * 3; // 48px
$g-spacer-900: $g-spacer-base * 4; // 64px
$g-spacer-925: $g-spacer-base * 4.5; // 72px
