// -----------------------------------------------------------------------------
// Custom filters
// -----------------------------------------------------------------------------
.filter-checkbox {
  position: relative;

  input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1rem;
    opacity: 0;
  }

  label {
    @include g-apply-text-style('medium-200');

    user-select: none;
    padding: $g-spacer-300 var(--g-grid-gutter, 1.5rem);
    background: $g-gray-200;
    border-radius: var(--g-border-radius);
    transition:
      background-color $g-transition-property,
      color $g-transition-property;

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label {
    color: $g-color-white;
    background: $g-gray-1000;
  }
}
