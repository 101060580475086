.app-footer {
  padding: var(--g-grid-gutter, 1.5rem);

  @include media-breakpoint-up(xl) {
    margin-left: $g-sidebar-width;
  }

  .mobile-page-view & {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
}
