.attachment {
  display: flex;
  width: 20.25rem;
  flex-direction: column;
  padding: 4px 4px 6px 4px;
  align-items: flex-start;
  border-radius: 0.625rem;
  background: #FFF;

  &--small {
    width: 100%;

    .library-card__header-wrapper {
      padding: 0.25rem 0.5rem;
      gap: 0.5rem;
    }

    .library-card__title {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .library-card__date {
      font-size: 0.625rem;
    }
  }

  &__content {
    align-self: stretch;
    padding: 0;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }

  &__header-wrapper {
    display: flex;
    padding: 0 6px 6px 6px;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    &--metadata {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
      flex: 1 0 0;
    }

    &--menu {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    padding-top: 8px;
  }

  &__title {
    color: var(--Color-Grey-900, #010028);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
  }

  &__date {
    font-size: 0.75rem;
    color: var(--Color-Grey-600);
  }
}
