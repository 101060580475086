// -----------------------------------------------------------------------------
// Placeholders
// -----------------------------------------------------------------------------

.placeholder {
  display: inline-block;
  vertical-align: middle;
  cursor: wait;
  opacity: $g-placeholder-opacity-max;
  background-color: $g-gray-300;
  border-radius: var(--g-border-radius);
  margin-right: $g-spacer-base;
  min-height: 0.8rem;
}

// Sizing
.placeholder-xs {
  min-height: 0.5rem;
}

.placeholder-sm {
  min-height: 1.1rem;
}

.placeholder-lg {
  min-height: 2rem;
}

.placeholder-glow {
  .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: $g-placeholder-opacity-min;
  }
}

// -----------------------------------------------------------------------------
// Extensions
// -----------------------------------------------------------------------------

.placeholder-panel {
  display: flex;
  padding: var(--g-grid-gutter);
  border-radius: var(--g-border-radius);
  background-color: $g-gray-100;
}
