// -----------------------------------------------------------------------------
// Extensions
// -----------------------------------------------------------------------------

.list-item-border {
  padding-top: $g-spacer-300;
  padding-bottom: $g-spacer-300;
  padding-left: $g-spacer-100;
  border-bottom: $g-border-width solid $g-border-color;
}
