.panel {
  padding: var(--g-grid-gutter-y, 2rem) var(--g-grid-gutter, 1.5rem);
  background-color: $g-color-white;
  border-radius: var(--g-border-radius);
}

.panel-primary {
  background: $g-color-white
    linear-gradient($g-color-primary-200, $g-color-primary-200);
  padding-top: $g-spacer-base;
  padding-bottom: $g-spacer-base;
  border-bottom: 2px solid $g-color-primary;
}

.panel-light-grey {
  background: $g-gray-100;
  padding-top: $g-spacer-base;
  padding-bottom: $g-spacer-base;
  border-bottom: 2px solid $g-gray-700;
}

.panel-light {
  position: relative;
  padding-top: $g-spacer-base;
  padding-bottom: $g-spacer-base;
  border: $g-border-width solid $g-border-color;

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    right: 1px;
    bottom: -3px;
    left: 1px;
    border-radius: var(--g-border-radius);
    border-bottom: $g-border-width solid $g-border-color;
  }
}

.panel-success {
  background-color: $g-color-success-100;
  box-shadow: 0 2px 0 $g-color-success-200;
  border: 0;
}

.panel-dashed {
  border: $g-border-width dashed $g-gray-300;
  border-radius: calc(var(--g-border-radius) * 2);
}

.panel-border {
  background-color: transparent;
  border: $g-border-width solid $g-border-color;
}

.panel-shadow {
  box-shadow: $g-box-shadow-200;
}

.panel-shadow-desktop {
  @include media-breakpoint-up(xl) {
    box-shadow: $g-box-shadow-200;
  }
}

.panel-shadow-border {
  box-shadow: $g-box-shadow-200;
  border: $g-border-width solid $g-gray-200;
}

.panel-hidden-mobile {
  padding: 0;

  @include media-breakpoint-up(xl) {
    padding: var(--g-grid-gutter-y) var(--g-grid-gutter);
  }
}
