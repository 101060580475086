// -----------------------------------------------------------------------------
// Dashboard
// -----------------------------------------------------------------------------

.dashboard {
  &__header {
    display: grid;
    position: relative;

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &__tile {
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
    padding-top: var(--g-grid-gutter);
    padding-bottom: 0;
  }

  &__tile-body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-bottom: var(--g-grid-gutter);
  }

  &__tile-footer {
    padding-top: $g-spacer-300;
    padding-bottom: $g-spacer-300;
    border-top: 1px solid $g-gray-200;
    text-align: right;
    line-height: 1;
  }

  &__tile-footer-link {
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
    }
  }

  &__note {
    flex-flow: column;
    align-items: stretch;
    width: 100%;
    padding: $g-spacer-base;
    text-align: left;
    font-weight: $g-fw-regular;
  }

  &__tab-content {
    &.tab-content {
      &,
      > .active {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
  }
}
