// -----------------------------------------------------------------------------
// Consultation page
// -----------------------------------------------------------------------------

.consult-head-title {
  color: $g-gray-700;
  margin-bottom: $g-spacer-200;
}

.consult-head-subtitle {
  font-size: $g-fs-300;
  font-weight: $g-font-weight-semibold;
  color: $g-gray-400;
  max-width: 236px;
}

.consult-block {
  display: flex;
  flex-direction: column;
  gap: $g-spacer-200;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 1px rgba(1, 0, 40, 0.13);
  padding: $g-spacer-base;
  cursor: pointer;
  &:hover {
    background: $g-gray-bg;
  }
  &:active {
    background: $g-gray-150;
  }
}

.consult-block-title {
  color: $g-color-black;
  font-size: $g-font-size-base;
  font-weight: $g-font-weight-bold;
}

.consult-block-description {
  color: $g-gray-450;
  font-size: $g-fs-300;
  font-weight: $g-font-weight-normal;
}

.consult-block-img {
  height: 36px;
  width: 36px;
}

.consult-head {
  display: flex;
  position: relative;
  align-items: center;
  height: g-px-to-rem(50px);
  min-height: g-px-to-rem(50px);
  font-size: $g-fs-300;
  font-weight: $g-font-weight-bold;
  padding: 0 $g-spacer-600;
  border-bottom: $g-border-width solid $g-gray-200;
}
.consult-head-label.collapsable {
  display: none;
}
.collapsed {
  .consult-head-label.collapsable {
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding-bottom: 1.5rem;
    font-size: clamp(0.75rem, 0.71rem + 0.18vw, 0.88rem);
    font-weight: 700;
    &.consult-head-label-right.collapsable {
      transform: rotate(0);
      padding-top: 1.5rem;
    }
  }
}

.consult-head-menu {
  padding: 0 16px;
  color: #868686;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  &.consult-head-menu-active {
    color: black;
    border-bottom: 2px solid #000;
  }
}

.mirror-x {
  transform: scaleX(-1);
}

.consult-left-column {
  flex-shrink: 0;
  width: g-px-to-rem(316px);
  transition: width 0.5s ease;
  border-right: $g-border-width solid $g-gray-200;
}

.consult-right-end-column {
  flex-shrink: 0;
  width: g-px-to-rem(316px);
  transition: width 0.5s ease;
  border-left: $g-border-width solid $g-gray-200;
}

.consult-left-column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $g-spacer-300 $g-spacer-600;
  gap: $g-spacer-300;
  height: calc(100% - 50px);
  overflow-y: auto;
}

.collapsed {
   .collapsable {
    display: none;
  }
  width: 72px !important;
}

.consult-right-column-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  align-items: center;
  overflow-y: auto;
  padding: $g-spacer-600 $g-spacer-700;
  gap: $g-spacer-300;
  position: relative;
}

.consult-right-end-column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $g-spacer-300 $g-spacer-600;
  gap: $g-spacer-300;
  height: calc(100% - 50px);
  overflow-y: auto;
}

.consult-right-column-content {
  background: $g-gray-150;
}

.consult-right-dummy-content {
  width: 322px;
}

.consult-right-dummy-content-text {
  font-size: $g-fs-300;
  font-weight: $g-font-weight-normal;
  color: $g-gray-350;
}
/**/
.ai-consult-top-link {
  font-size: $g-fs-14;
  font-weight: $g-font-weight-semibold;
  color: $g-gray-1000;
  padding: $g-spacer-150 $g-spacer-base;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.magnifier {
  margin-left: 10px;
}

.manual-consult__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0;
  align-self: stretch;
}

.manual-consult__simplified-title {
  overflow: hidden;
  color: var(--Color-Grey-700, #6A6D70);
  text-overflow: ellipsis;
  font-family: "Uncut Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 2.24px;
  text-transform: uppercase;
}

.manual-consult__attachments-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Color-Grey-600, #999B9D);
  text-overflow: ellipsis;
  align-self: start;
  margin-bottom: 0.5rem;

/* Headline/h7 (14px) */
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: 1rem; /* 114.286% */
letter-spacing: 0.14rem;
text-transform: uppercase;
}

.manual-consult__attachments-section {
  display: flex;
  /*max-width: 37.5rem;*/
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.library-list {
  width: 100%;
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    .search-input {
      width: 100%;
    }
  }

  &__search-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--Color-grey-grey-700, #746F75);
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    padding-bottom: 0.5rem;

    &-title {
      display: flex;
      padding: 0rem 1.5rem 0 0rem;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
      color: #000;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: 0.14rem;
      text-transform: uppercase;
      opacity: 0.4;
    }

    &-list-wrapper {
      display: flex;
      padding: 0 1rem 0 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .library-list__empty-message {
        display: flex;
        padding: 0.5rem 0 0.5rem 0;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        color: var(--Color-Grey-600, #999B9D);
        font-size: 0.875rem;
      }
    }

    &-item-wrapper {
      display: flex;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 0.625rem;
      background: #FFF;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0.5rem 0 0.5rem 0;
      align-self: stretch;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      &-title {
        color: var(--Color-Grey-800, $g-gray-700);
      }
    }
  }
}
